import React from "react";

// Style
// import "./circleIconButton.scss";

import { computeStyle } from "../../../imports/utils";
import { theme } from "./circleIconButton.theme";

const CircleIconButton = ({
  icon = null,
  onClick = null,
  styleType = "primary",
  style = null,
  disabled = false,
  className = "",
}) => {
  const handleClick = event => {
    onClick && onClick();
  };

  const color = styleType => {
    if (styleType === "primary") {
      return "primary";
    } else if (styleType === "secondary") {
      return "secondary";
    } else {
      return "primary";
    }
  };

  const iconButtonClass = computeStyle(theme, styleType, "disabled", disabled);

  return (
    <div
      id="iconButton"
      //add something to enable it
      //class="absolute"
      className={className}
    >
      <button
        onClick={handleClick}
        className={iconButtonClass}
        size="small"
        disabled={disabled}
        style={style}
      >
        <div
          //icon-box
          className="box-border flex items-center justify-center"
        >
          {icon}
        </div>
      </button>
    </div>
  );
};

export default CircleIconButton;
