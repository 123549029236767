import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../redux-observables/firebase/context";

const LANGUAGES = [
  { value: "en", label: "languages.english" },
  { value: "it", label: "languages.italian" },
  { value: "fr", label: "languages.french" },
  { value: "de", label: "languages.deutsh" },
  { value: "es", label: "languages.spanish" },
  { value: "ja", label: "languages.japanese" },
];

export default function LanguageSelector({ className = "" }) {
  const { t } = useTranslation();
  const { changeLanguage, language } = useContext(AuthContext);

  return (
    <div className={"flex flex-col items-center justify-center w-full"}>
      <span className="mb-2 text-white">{t("language")}</span>
      <select
        className={`px-2 py-1 text-white border border-white bg-primaryBackground ${className}`}
        name="language"
        id="language"
        onChange={e => {
          changeLanguage(e.target.value);
        }}
        defaultValue={language}
      >
        {LANGUAGES.map(lang => (
          <option
            key={lang.value}
            value={lang.value}
          >
            {t(lang.label)}
          </option>
        ))}
      </select>
    </div>
  );
}
