import { Formik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
// import { useAuthSendPasswordResetEmail } from "@react-query-firebase/auth";

import { useNavigate } from "react-router-dom";
import { CustomInput, StyledButton } from "../../components";
import i18n from "../../imports/i18n";
import { AuthContext } from "../../redux-observables/firebase/context";
import { firstStepValidation, secondStepValidation } from "./FormType";

// import "./resetPassword.scss";

function ResetPassword(props) {
  const navigate = useNavigate();

  const { resetPassword, sendNewPassword, user } = useContext(AuthContext);

  const [step, setStep] = useState(0);
  const [values, setValues] = useState({});
  const [recoverId, setRecoverId] = useState(null);

  const { refetch } = useQuery(
    "passwordHandler",
    async () => {
      // if (step === 0) {
      //   await resetPassword(values.email);
      // } else {
      if (await sendNewPassword(values.password, user.uid)) {
        navigate({
          pathname: "/auth",
          state: {
            toast: {
              message: "reset_password.password_changed",
              type: "success",
            },
          },
        });
      }
      // }
    },
    { enabled: false },
  );

  // useEffect(() => {
  //   if (history.location.state?.oobCode) {
  //     setRecoverId(history.location.state.oobCode);
  //     setStep(1);
  //   }
  // }, []);

  useEffect(() => {
    if (values.password) refetch();
  }, [values]);

  return (
    <div
      id={`resetPassword`}
      className={`w-full flex flex-col justify-center bg-primaryBackground`}
      style={{ minHeight: "calc(100vh-80px" }}
    >
      <div className="flex flex-col items-center justify-center my-10 text-secondaryGrey">
        <p className="px-3 mb-4 text-center">
          {i18n.t("reset_password.subtitle")}
        </p>
        <span>{user.email}</span>
      </div>
      <Formik
        initialValues={{ password: "", checkPassword: "" }}
        validationSchema={secondStepValidation}
        onSubmit={values => {
          setValues(values);
        }}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,

          setFieldValue,
        }) => (
          <div
            //reset-password-container
            className="flex flex-col items-center justify-start w-full px-30"
          >
            <>
              <CustomInput
                type={"password"}
                label={i18n.t("reset_password.password")}
                value={values.password}
                onChange={e => setFieldValue("password", e)}
                errors={errors.password}
              />
              <CustomInput
                type={"password"}
                label={i18n.t("reset_password.check_password")}
                value={values.checkPassword}
                onChange={e => setFieldValue("checkPassword", e)}
                errors={errors.checkPassword}
              />
            </>

            <StyledButton
              styleType="primary"
              onClick={handleSubmit}
              // isDisabled={
              //     !(values.email && !step) ||
              //     !(values.password === values.checkPassword)
              // }
            >
              {i18n.t("reset_password.save_new_password")}
            </StyledButton>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default ResetPassword;
