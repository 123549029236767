import React from "react";
import { useTranslation } from "react-i18next";
import StyledButton from "../../styledButton/styledButton";
import { CustomModal } from "../customModal";

export default function TransferModal({ opened, setCloseModal }) {
  const { t } = useTranslation();
  return (
    <CustomModal
      opened={opened}
      content={
        <div className="box-border flex flex-col items-center w-full h-full p-4 rounded-20 xs:justify-start justify-evenly bg-primaryBackground text-secondaryGrey">
          <p className="text-14 ">{t("popup.transfer.title")}</p>

          <div className="flex flex-col justify-center text-center text-12 text-secondarygrey items-between">
            <p className="my-2">{t("popup.transfer.disclaimer1")}</p>
            <p className="my-2">{t("popup.transfer.disclaimer2")}</p>
          </div>
          <div
            //buttons-container
            className="flex flex-row items-center justify-around w-full"
          >
            <StyledButton
              onClick={setCloseModal}
              styleType="primary"
              className="mx-10"
            >
              {t("popup.transfer.ok")}
            </StyledButton>
          </div>
        </div>
      }
      style={{ width: "80%", maxWidth: "450px", height: "unset" }}
      onBackdropClick={() => setCloseModal(false)}
    />
  );
}
