import React from "react";

import { Check } from "../../imports/animations/index";
import Lottie from "react-lottie-player";
import i18n from "../../imports/i18n";

export default function CheckStep({ steps, currentStep }) {
  return (
    <div className="flex flex-col">
      {steps.map(({ label }, i) => (
        <div key={label} className="flex items-center justify-center">
          <div className="flex items-center justify-center w-16 h-16">
            <Lottie
              loop={false}
              play={currentStep === i}
              goTo={currentStep > i ? 120 : 0}
              animationData={Check}
            />
          </div>
          <p className="flex-1 leading-5 text-left text-12">{i18n.t(label)}</p>
        </div>
      ))}
    </div>
  );
}
