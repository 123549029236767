import { getCarObject } from "../../imports/constants";
import {
  encryptTokenURI,
  getDecryptTokenURI,
  sdk,
  regenerateMetadata,
} from "../../imports/utils";
import EthCrypto from "eth-crypto";

import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../redux-observables/firebase/firebase";
import { ethers } from "ethers";

export async function getUserByAddr(address) {
  const q = query(
    collection(db, "users"),
    where("address", "==", address.toLowerCase()),
  );
  const { docs } = await getDocs(q);
  return docs[0]?.data();
}

export async function transferNFTCall(
  publicKey,
  tokenId,
  metadata,
  vin,
  brand,
  model,
) {
  try {
    const { abi, certificate: address } = getCarObject(model);

    if (!abi || !address) {
      throw new Error("Unexistent car");
    }

    const newTokenURI = await regenerateMetadata(
      tokenId,
      publicKey,
      brand,
      model,
    );
    // const tokenURI = await encryptTokenURI(
    //   publicKey,
    //   await getDecryptTokenURI(tokenId, model),
    // );

    const receiver = ethers.utils.computeAddress(publicKey);

    const metaTx = await sdk.prepareTransaction(
      {
        address: address,
        abi: abi,
        name: "Certificates",
        version: "0.0.1",
      },
      "transferCertificate",
      [receiver, newTokenURI, ethers.utils.keccak256(Buffer.from(vin))],
    );

    const requestId = await sdk.executeAsyncTransaction(metaTx, {
      webhookUrl: `${process.env.REACT_APP_FIREBASE_ENDPOINT}/saveTransferReceipt`,
      verbose: true,
      metadata,
    }); //optionals webhook address

    await updateDoc(doc(db, "nfts", metadata.nftId), {
      transfer: { underTransfer: true, requestId },
    });
    return { requestId };
  } catch (err) {
    console.log(err);
    return { error: "cant_transfer" };
  }
}
