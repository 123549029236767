//react
import React, { useEffect } from "react";
import i18n from "../../imports/i18n";
import { computeStyle } from "../../imports/utils";

//components
import {
  Icons,
  Icon,
  CustomCheckbox,
  CustomModal,
  StyledButton,
} from "../index";
import { MessageBox } from "../messageBox/messageBox";
import { theme, text } from "./customInput.theme";
import { VisibilityOff, VisibilityRounded } from "@material-ui/icons";
import { colors } from "../../imports/constants";

//style
//import "./customInput.scss";

export const CustomInput = ({
  label = "",
  type = "text",
  placeholder,
  value = "",
  charLimit = null,
  initialValue = null,
  icon = null,
  labelIcon = false,
  onChange = null,
  errors = false,
  styleType = "primary",
  link = null,
  modalMessage = null,
  doubleCheck = false,
  inputStyle = {},
  disabled = false,
  ...props
}) => {
  const [formValue, changeValue] = React.useState(
    type === "check"
      ? value === ""
        ? false
        : value
      : value || initialValue || "",
  );

  const [openModal, setOpenModal] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleChange = e => {
    if (charLimit) {
      if (e.length <= charLimit) {
        changeValue(e);
        onChange && onChange(e);
      }
    } else {
      changeValue(e);
      onChange && onChange(e);
    }
  };

  useEffect(() => {
    changeValue(
      type === "check"
        ? value === ""
          ? false
          : value
        : value || initialValue || "",
    );
  }, [value]);

  const modal = () => {
    return (
      <CustomModal
        opened={openModal}
        content={
          <div
            id={"conditionModal"}
            className={`flex items-center justify-center flex-col`}
          >
            <p>{modalMessage}</p>
            <div
              //buttons-container
              className="flex flex-row items-center justify-around w-full"
            >
              <StyledButton
                onClick={() => {
                  setOpenModal(false);
                  handleChange(false);
                }}
                styleType="secondary"
                style={{ margin: 5 }}
              >
                {i18n.t("forms.decline")}
              </StyledButton>
              <StyledButton
                onClick={() => {
                  handleChange(!formValue);
                  setOpenModal(false);
                }}
                styleType="primary"
                style={{ margin: 5 }}
              >
                {i18n.t("forms.accept")}
              </StyledButton>
            </div>
          </div>
        }
        style={{ width: "80%", maxWidth: "450px", height: "unset" }}
        onBackdropClick={() => setOpenModal(false)}
      />
    );
  };

  const containerClass = computeStyle(theme, styleType);
  const labelClass = computeStyle(text, styleType);

  return (
    <div id={`customInput`} className={containerClass + " max-w-450"}>
      {modal()}
      {label && type !== "check" && (
        <label className={labelClass}>
          {label} {labelIcon && icon && icon}
        </label>
      )}
      <div className="relative">
        {type === "multiline" ? (
          <MessageBox
            value={formValue}
            onChange={value => handleChange(value)}
            rows={2}
            charLimit={charLimit}
            placeholder={placeholder}
          />
        ) : type === "check" ? (
          <div
            //checkbox-container
            className="flex flex-row items-start justify-center"
          >
            <CustomCheckbox
              label={
                <a
                  href={link}
                  target={"_blank"}
                  className="label"
                  rel="noreferrer"
                >
                  {label}
                </a>
              }
              checked={formValue}
              onClick={() => {
                doubleCheck && !formValue
                  ? setOpenModal(true)
                  : handleChange(!formValue);
              }}
            />
          </div>
        ) : (
          <input
            //TODO
            className={`${errors && "border-2 border-error border-r-40"} ${
              disabled && "bg-greyDemo"
            } w-full h-10 text-sm box-border px-4 py-4 text-black font-normal bg-lightGrey rounded-10 outline-none`}
            type={showPassword ? "text" : type}
            placeholder={placeholder}
            value={formValue}
            onChange={e => handleChange(e.target.value)}
            // id={type === "file" ? "upload" : ""}
            style={inputStyle}
            disabled={disabled}
          />
        )}

        {type !== "file" && (
          <div
            //form-icon-position
            className={`absolute right-0 bottom-0 flex justify-center items-center h-10 box-border`}
          >
            <>
              {type === "password" && (
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className={"cursor-pointer"}
                >
                  {showPassword ? (
                    <VisibilityRounded
                      fill={"#fff"}
                      style={{
                        width: 30,
                        height: 30,
                        marginRight: 10,
                        color: colors.grey,
                      }}
                    />
                  ) : (
                    <VisibilityOff
                      fill={"#fff"}
                      style={{
                        width: 30,
                        height: 30,
                        marginRight: 10,
                        color: colors.grey,
                      }}
                    />
                  )}
                </div>
              )}
              {errors ? (
                <Icon
                  name={Icons.ERRORCIRCLE}
                  fill={"#fff"}
                  style={{
                    width: 30,
                    height: 30,
                    marginRight: 5,
                  }}
                />
              ) : (
                !labelIcon && icon
              )}
            </>
          </div>
        )}
      </div>
      {errors && (
        <span className="text-11 text-error">{i18n.t(`errors.${errors}`)}</span>
      )}
    </div>
  );
};
