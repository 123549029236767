import React from "react";

//components
import { CircleIconButton, Icons, Icon } from "../index";

//style
// import "./defaultCard.scss";
import { colors } from "../../imports/constants";
import i18n from "../../imports/i18n";

function DefaultCard({
  topIcon = null,
  onClick = null,
  optionsClick = null,
  title = "",
  info = [],
  isDisabled = false,
  status = false,
  className = "",
}) {
  return (
    <div
      className={`cursor-pointer rounded-20 w-full h-full p-1 flex max-w-450 justify-center items-center flex-col box-border bg-primaryGradient ${className}`}
      style={{
        boxShadow: "0px 8px 20px -12px rgba(0, 0, 0, 0.25)",
      }}
      onClick={() => onClick && onClick()}
    >
      <div
        className={`w-full pl-2 pr-2 pt-2 flex justify-between items-center box-border  transition ease-in-out delay-150`}
      >
        <span className={`text-16 text-white`}>{title}</span>
        {topIcon && <div className="icon-top-box">{topIcon}</div>}
      </div>
      <div
        className={`w-full text-xl flex justify-between items-center box-border pt-2 pl-0 pr-0 flex-col overflow-hidden text-white transition ease-in-out delay-150`}
      >
        <div
          className={`w-full pl-2 pr-2 flex-row  items-center box-border pt-1 pb-1 text-base justify-between text-white transition ease-in-out delay-150`}
        >
          {info.map((title, i) => {
            return (
              <div key={i} className="flex items-center w-full">
                <span className="text-14">{`${i18n.t(title.key)}: `}</span>
                <span className="ml-3 text-12">{title.value}</span>
                {optionsClick && i === info.length - 1 && (
                  <CircleIconButton
                    size="small"
                    onClick={() => optionsClick()}
                    icon={
                      <Icon
                        name={Icons.OPTIONS}
                        fill={colors["black"]}
                        style={{
                          width: 15,
                          height: 15,
                        }}
                      />
                    }
                    disabled={!optionsClick}
                    styleType={"secondary"}
                    className={`bg-transparent ml-auto shadow-none`}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DefaultCard;
