//react
import React from "react";

//style
// import "./styledButton.scss";
import { theme, text } from "./styledButton.theme";
import { computeStyle } from "../../imports/utils";

const StyledButton = ({
  styleType = "primary",
  onClick = null,
  isDisabled = false,
  children = "no children",
  leftIcon = null,
  rightIcon = null,
  className = "",
  buttonStyle = {},
  style = {},
  ...props
}) => {
  // const classes =
  //     styleType === "primary"
  //         ? `primary ${className}`
  //         : styleType === "secondary"
  //         ? `secondary ${className}`
  //         : styleType === "tertiary"
  //         ? `tertiary ${className}`
  //         : styleType === "avatarUpload"
  //         ? `avatarUpload ${className}`
  //         : `black ${className}`;
  const classes = computeStyle(
    theme,
    styleType,
    "disabled",
    isDisabled,
    className,
  );
  const textClasses = computeStyle(text, styleType, "disabled", isDisabled);

  return (
    <div
      id="customButton"
      style={{ ...style }}
      className={` w-full max-w-350  mt-4 relative box-border flex items-center justify-center flex-col mx-auto`}
    >
      <button
        onClick={onClick}
        type="button"
        // variant={styleType === "secondary" ? "outlined" : "contained"}
        disabled={isDisabled}
        className={classes}
        style={buttonStyle}
        {...props}
      >
        <div className={`left-icon ${!leftIcon ? "w-8 " : ""}`}>{leftIcon}</div>

        <span
          className={textClasses}
          style={{ color: buttonStyle.color && buttonStyle.color }}
        >
          {children}
        </span>
        <div className={`right-icon ${!rightIcon ? "w-8 " : ""}`}>
          {rightIcon}
        </div>
      </button>
    </div>
  );
};

export default StyledButton;
