/* Homepage: every minted nft will be listed here
divided by status (based on tabs choice) */

//react
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Firebase
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { query, collection, where } from "firebase/firestore";
import { db } from "../../redux-observables/firebase/firebase";

//components
import {
  // SquareIconButton,
  // CustomInput,
  Icons,
  Icon,
  DefaultCard,
  Loader,
  StyledButton,
  // CustomModal,
} from "../../components/index";
// import QRCodeSVG from "qrcode.react";
// import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { colors } from "../../imports/constants";

//i18n
import i18n from "../../imports/i18n";

// import Lottie from "react-lottie-player";
// import { Loading } from "../../imports/animations";
// import { Formik } from "formik";
// import { AuthContext } from "../../redux-observables/firebase/context";
// import { recoveryPasswordValidation } from "./FormType";
import { sdk } from "../../imports/utils";
import { uniqBy } from "lodash";
import { utils } from "ethers";
// import EthCrypto from "eth-crypto";
import TransferModal from "../../components/popup/transferModal/transferModal";

export default function Home(props) {
  const navigate = useNavigate();
  // const { user, recoverWallet, action, loading } = useContext(AuthContext);
  const [nfts, setNfts] = useState([]);

  // const {
  //   data: nftsCreated,
  //   isLoading: isLoadingCreated,
  //   refetch: refetchCreated,
  // } = useFirestoreQueryData(
  //   ["nftsCreated"],
  //   query(
  //     collection(db, "nfts"),
  //     where("createdBy", "==", sdk.getWalletAddress().toLowerCase()),
  //   ),
  //   { subscribe: true },
  // );

  //console.log('walletAddress', sdk.getWalletAddress().toLowerCase())

  const {
    data: nftsOwned,
    isLoading: isLoadingOwned,
    refetch: refetchOwned,
  } = useFirestoreQueryData(
    ["nftsOwned"],
    query(
      collection(db, "nfts"),
      where("owner", "==", sdk.getWalletAddress().toLowerCase()),
    ),
    { subscribe: true },
  );

  const [buttonFilter, setButtonFilter] = useState("wallet");
  const [showQRCode, setShowQRCode] = useState(false);
  const [nftFilter, setFilterDoc] = useState({
    wallet: [],
    pending: [],
    transferred: [],
  });

  useEffect(() => {
    // refetchCreated();
    refetchOwned();
  }, []);

  useEffect(() => {
    if (nftsOwned) {
      setNfts(uniqBy([...nftsOwned], "id"));
    }
  }, [nftsOwned]);

  //console.log('nftsOwned', nftsOwned)

  const handleFilter = e => {
    const filters = {
      wallet: nfts =>
        nfts.filter(
          nft =>
            nft.owner === sdk.getWalletAddress().toLowerCase() &&
            nft.status === "success",
        ),
      pending: nfts => nfts.filter(nft => nft.status === "pending"),
      transferred: nfts =>
        nfts.filter(nft => nft.owner !== sdk.getWalletAddress().toLowerCase()),
    };
    if (e && nfts) {
      setFilterDoc({
        ...nftFilter,
        [buttonFilter]: filters[buttonFilter](nfts),
      });
    } else {
      setFilterDoc({ wallet: [], pending: [], transferred: [] });
    }
  };

  useEffect(() => handleFilter(buttonFilter), [buttonFilter, nfts, nftsOwned]);

  const NftCards = () => {
    return nftFilter[buttonFilter].length > 0 ? (
      nftFilter[buttonFilter].map((item, i) => {
        const icon = (
          <Icon
            fill={colors.primary}
            name={Icons.NFT}
            style={{ width: 40, height: 40 }}
          />
        );

        return (
          <DefaultCard
            className="mb-4"
            key={i}
            // title={item.name.replaceAll("_", " ")}
            title={`${item.carData.brand.toUpperCase()} ${item.carData.model.toUpperCase()}`}
            info={[
              { key: "contracts.vin", value: item.carData.vin },
              {
                key: "contracts.date",
                value: new Date(item.updatedAt).toLocaleDateString("it-IT"),
              },
            ]}
            topIcon={icon}
            //isDisabled={item?.tokenIds}
            onClick={() => {
              if (item)
                navigate(
                  `/nft-detail/${utils.keccak256(
                    Buffer.from(item.carData.vin),
                  )}`,
                );
            }}
          />
        );
      })
    ) : (
      <div
        className={`w-full flex flex-col justify-center items-center text-14 text-center font-normal text-secondaryGrey`}
      >
        <span>{i18n.t(`nft_cards.no_nft_found`)}</span>
        <span>{i18n.t(`nft_cards.no_nft_found2`)}</span>
      </div>
    );
  };

  return (
    <div
      className={`w-full flex justify-center flex-col bg-primaryBackground px-30`}
    >
      <TransferModal
        opened={showQRCode}
        setCloseModal={() => setShowQRCode(false)}
      />
      <div
        className={`w-full pt-5 box-border flex flex-col items-center justify-start z-1 bg-primaryBackground`}
      >
        {!!nftFilter[buttonFilter].length && (
          <div className={`pb-4 text-14 text-center text-white`}>
            {i18n.t("home.click_to_view")}
          </div>
        )}
      </div>
      <div className="flex flex-col items-center justify-start w-full pb-20 overflow-y-auto">
        {isLoadingOwned ? <Loader type={""} /> : <NftCards />}
      </div>

      <StyledButton
        style={{
          position: "fixed",
          bottom: "35px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        styleType="primary"
        onClick={() => {
          setShowQRCode(true);
        }}
      >
        {i18n.t("home.receive_nft")}
      </StyledButton>
    </div>
  );
}
