import { format } from "date-fns";
import { colors } from "../../imports/constants";
import { Icon, Icons } from "../icons/icons";
import i18n from "../../imports/i18n";

import Cross from "../../assets/cross.png";
import NFTImage from "../../assets/bg-cert.png";
import NewNFTImage from "../../assets/new_nft.jpg";

const CAR_FIELDS = ["brand", "model", "vin", "mileage"];

const NftCertificate = ({ carData }) => {
  const date = new Date();
  return (
    <div
      id="nftCertificate"
      className="w-full bg-primaryBackground pt-topBarHeight"
    >
      <div className="absolute top-0 flex items-center justify-between w-full px-4 py-5 bg-secondaryBackground h-topBarHeight">
        {/* <div className="w-8 h-8" /> */}
        <span className="px-3 text-lg text-white">
          {i18n.t("carField.digital_report")}
        </span>
        <Icon
          name={Icons.ALFA_ROMEO_LOGO_BI}
          fill={colors.white}
          style={{
            width: 35,
            height: 35,
          }}
        />
      </div>
      <div className="bg-primaryBackground">
        {!carData.model.toLowerCase().includes("tonale") ? (
          <img src={NewNFTImage} alt="cert" />
        ) : (
          <img src={NFTImage} alt="cert" />
        )}
        <div className="flex flex-col items-center pb-20">
          <img src={Cross} alt="cross" className="w-12 h-12 my-4" />
          <div className="flex flex-col items-center justify-center w-full h-8 mt-4 mb-8">
            <span className="text-2xl font-bold text-center whitespace-normal text-greyDemo">
              {i18n.t("nft_detail.data_checked").toUpperCase()}
            </span>
            <span className="text-lg font-bold text-center whitespace-normal text-greyDemo">
              {`${date.getDate()} ${i18n.t(
                `months.${date.getMonth()}`,
              )} ${date.getFullYear()}`}
            </span>
          </div>
          <div className="flex flex-col items-center">
            {CAR_FIELDS.map(key => (
              <div key={key} className="flex items-center">
                <span className="font-bold whitespace-normal text-16 text-greyDemo">
                  {i18n.t(`carField.${key}`)}{" "}
                  {carData[key]?.toString().toUpperCase()}{" "}
                  {key === "mileage" ? "km" : ""}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftCertificate;
