import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../redux-observables/firebase/firebase";

export async function waitForTransactionStatusChanges(docId) {
  // Inizializzazione della Promise
  return new Promise((resolve, reject) => {
    // Imposta un timer per il timeout
    const timeoutMs = 300 * 1000;
    const timer = setTimeout(() => {
      console.log(`Timeout: no status update in ${timeoutMs / 1000} seconds.`);
      unsub(); // Annulla la sottoscrizione in caso di timeout
      reject(
        new Error(
          `Timeout error: no status update in ${timeoutMs / 1000} seconds.`,
        ),
      );
    }, timeoutMs);

    // La funzione onSnapshot continua ad ascoltare le modifiche finché non viene chiamato unsub().
    const unsub = onSnapshot(
      doc(db, "txs", docId),
      document => {
        // console.log("TRY Current data: ", document.data());
        if (
          document.exists() &&
          ["QUEUED", "PENDING"].includes(document.data().status) === false
        ) {
          clearTimeout(timer); // Cancella il timer qui
          // Se lo stato è diverso da QUEUED o PENDING
          const status = document.data().status;
          unsub(); // Annulla la sottoscrizione per evitare ulteriori aggiornamenti
          resolve(status); // Risolvi la promise con lo stato
        }
      },
      errore => {
        // console.log('ERRORE onSnapshot', errore)
        clearTimeout(timer); // Cancella il timer qui
        // Gestisci errori nell'ascolto del documento
        unsub();
        reject(errore);
      },
    );
  });
}
