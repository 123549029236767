//react
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//components
import {
  StyledButton,
  CustomInput,
  Icons,
  Icon,
  CustomCheckbox,
  Loader,
} from "../../components/index";
import { toast } from "react-toastify";

//Lottie
import { Loading } from "../../imports/animations/index";
import Lottie from "react-lottie-player";

//i18n
import i18n from "../../imports/i18n";

//formik
import { Formik } from "formik";

//config
import { loginInitialValues, loginValidation } from "../../imports/config";

//style
import SplashScreen from "../../components/desktopComponent/splashScreen/splashScreen";
import { AuthContext } from "../../redux-observables/firebase/context";
import {
  getPrivacyPolicy,
  getTermsAndConditions,
} from "../../redux-observables/firebase/firebase";
import { colors, TOAST_CONFIG } from "../../imports/constants";
import {
  signupFormStepTwo,
  signupInitialValues,
  signupValidationStepTwo,
  checkFieldCompletition,
} from "./formValidation";
import { isEmpty } from "lodash";
import LanguageSelector from "../../components/languageSelector/languageSelector";

const Authentication = props => {
  // const [loading, setLoading] = useState(false);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("");
  const [termsAndConditionsUrl, setTermsAndConditionsUrl] = useState("");

  const {
    user,
    loginUser,
    // saveRegion,
    isLoading: loading,
    registerUser,
    getRedirectUrl,
  } = useContext(AuthContext);

  const navigate = useNavigate();
  const [acceptingTerms, setAcceptingTerms] = useState(false);

  const loginForm = [
    {
      label: i18n.t("forms.password"),
      type: "password",
      formName: "password",
      placeholder: i18n.t("forms_placeholders.password"),
    },
  ];

  /**
   * User gets redirect to auth page:
   * Before AL: TW ask to access to your account (Account Linking Procedure)
   * After AL: User gets redirect to auth page from Gigya, with token.
   * and TW ask stellantis backend for access token and user data
   */
  useEffect(() => {
    const effect = async () => {
      if (user?.logged) {
        toast.success(i18n.t("messages.succesfully_registered"), TOAST_CONFIG);
      }
    };

    effect();

    getPrivacyPolicy().then(setPrivacyPolicyUrl);
    getTermsAndConditions().then(setTermsAndConditionsUrl);

    //TODO: if token is present, call  getStellantisAccessToken (After AL), else do nothing (Before AL)
  }, []);

  useEffect(() => {
    if (user?.logged) {
      props.navigate("/createNFT");
    } else if (!user.email) {
      const redirectUrl = getRedirectUrl();
      // console.log("getRedirectUrl", redirectUrl);
      window.location.href = redirectUrl;
    } else {
      // console.log("user is on knobs side");
      /*
       * Setting this value means that the user has been able to enter the app, so you can then block the region setting via autodetection that is done in App.js.
       * This happens every time you do navigate("/") or similar in the app, because it will retract the App.js page.
       * This makes it possible to handle a corner case (which should never occur) i.e. I am for example timezone in europe but initially receive the link https://tonale-wallet-dev.knobs.it?region=na.
       * Also in prod the autodetection will not be present so this check will be missing.
       * */
      localStorage.setItem("userEntered", true);
    }
  }, [user]);

  return loading ? (
    <SplashScreen type={"pending"} message={i18n.t("messages.login")} />
  ) : (
    <div className="box-border flex flex-col items-center justify-around w-full h-full color-primary">
      <div className="flex flex-col items-center justify-around w-full">
        <div className="flex flex-col items-center justify-center mt-8 mb-4">
          {/* <AlfaRomeoLogo width={200} height={200} className="my-10" /> */}
          <Icon
            name={Icons.ALFA_ROMEO_LOGO_BI}
            fill={colors.secondaryText}
            secondaryfill={colors.secondaryText}
            style={{ width: 120, height: 120 }}
          />
        </div>
        {/* <div className="text-4xl text-primary mb-60">{i18n.t("login.login")}</div> */}
        <div className="">
          {user?.email ? (
            loading ? (
              <div
                className={`relative w-40 h-40 m-auto top-1/3`}
                style={{ height: "calc(100vh-80px" }}
              >
                <Lottie play animationData={Loading} />
              </div>
            ) : user?.exists ? (
              <>
                <Formik
                  initialValues={loginInitialValues}
                  validationSchema={loginValidation}
                  onSubmit={values => {
                    loginUser(user.email, values.password);
                  }}
                >
                  {({ handleChange, handleSubmit, values, errors }) => (
                    <>
                      <div className="flex flex-col items-center justify-center w-full px-30">
                        {/* <span className="mt-4 text-center text-white text-14">
                        {i18n.t("login.insert_password_1")}
                      </span> */}
                        <span className="mb-4 text-center text-white text-14">
                          {i18n.t("login.insert_password_2")}
                        </span>
                        <CustomInput
                          type={"text"}
                          label={i18n.t("login.signin_as")}
                          value={user.email}
                          disabled={true}
                        />
                        {loginForm.map((el, i) => (
                          <CustomInput
                            key={i}
                            type={el.type}
                            label={el.label}
                            value={values[el.formName]}
                            placeholder={el.placeholder || null}
                            onChange={handleChange(el.formName)}
                            errors={errors[el.formName]}
                          />
                        ))}
                      </div>
                      <div className="flex flex-col items-center justify-around w-full px-30">
                        <StyledButton
                          styleType="primary"
                          onClick={handleSubmit}
                        >
                          {i18n.t("login.login")}
                        </StyledButton>
                      </div>
                    </>
                  )}
                </Formik>
              </>
            ) : (
              <>
                <Formik
                  initialValues={signupInitialValues}
                  validationSchema={signupValidationStepTwo}
                  onSubmit={values => {
                    registerUser({ ...values, email: user.email });
                  }}
                >
                  {({ handleSubmit, values, errors, setFieldValue }) => (
                    <>
                      <div className="flex flex-col items-center justify-center w-full px-30">
                        {signupFormStepTwo.map((el, i) =>
                          el.type !== "onlyText" ? (
                            <CustomInput
                              key={i}
                              type={el.type}
                              label={i18n.t(el.label)}
                              value={values[el.formName]}
                              placeholder={i18n.t(el.placeholder) || null}
                              onChange={value =>
                                setFieldValue(el.formName, value)
                              }
                              errors={errors[el.formName]}
                              link={el.link}
                              modalMessage={el.modalMessage}
                              doubleCheck={el.doubleCheck}
                            />
                          ) : (
                            <div
                              key={i}
                              className="flex flex-col w-full text-left max-w-450 align-center text-secondaryGrey"
                            >
                              <span className="mt-2 mb-4 text-xs text-center">
                                {i18n.t("signup.disclaimer1")}
                              </span>
                              <CustomInput
                                type={"text"}
                                label={i18n.t("signup.signup_as")}
                                value={user.email}
                                disabled={true}
                              />
                            </div>
                          ),
                        )}

                        <CustomCheckbox
                          className="justify-start w-full max-w-450"
                          label={
                            <a
                              href={termsAndConditionsUrl}
                              className="w-full text-secondaryGrey text-12"
                            >
                              {i18n.t("signup.terms_condition")}
                            </a>
                          }
                          onClick={() => {
                            setAcceptingTerms(!acceptingTerms);
                          }}
                          checked={acceptingTerms}
                        />
                      </div>
                      <div
                        className="flex flex-col items-center justify-around px-30"
                        style={{
                          maxHeight: "230px",
                        }}
                      >
                        <StyledButton
                          styleType="primary"
                          onClick={handleSubmit}
                          isDisabled={
                            !isEmpty(errors) ||
                            checkFieldCompletition(values) ||
                            !acceptingTerms
                          }
                        >
                          {i18n.t("signup.signup")}
                        </StyledButton>
                        <a
                          href={privacyPolicyUrl}
                          className="w-full mt-3 text-center text-secondaryGrey text-12"
                          // onClick={() => setTC(true)}
                        >
                          {i18n.t("signup.privacy_policy")}
                        </a>
                      </div>
                    </>
                  )}
                </Formik>
              </>
            )
          ) : (
            <div className="flex flex-col items-center justify-around w-full h-48 mt-60 px-30">
              <span className="flex flex-col m-auto text-sm text-center text-secondaryGrey max-w-450">{`${i18n.t(
                "login.gigya_redirecting",
              )}`}</span>
              {/* <StyledButton
              styleType="primary"
              onClick={() => {
                console
                  .log
                  // `${process.env.REACT_APP_GIGYA_ENDPOINT}/${process.env.REACT_APP_GIGYA_API_KEY}/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`,
                  ();
                window.location.href = `${process.env.REACT_APP_GIGYA_ENDPOINT}/${process.env.REACT_APP_GIGYA_API_KEY}/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
              }}
              style={{ marginBottom: "15px" }}
            >
              {i18n.t("login.login")}
            </StyledButton> */}
              <Loader type={""} />
            </div>
          )}
        </div>
        <div className="flex flex-col items-center no-underline">
          {/* <a
          className="mb-3 text-text text-14"
          href="mailto:info@bcode.cloud?subject=Problemi di accesso"
        >
          {i18n.t("forms.issues")}
        </a> */}
          {user?.email && user?.exists && (
            <span
              className=" cursor-pointer text-text text-14 mt-4"
              onClick={() => navigate("/resetPassword")}
            >
              {i18n.t("forms.forgot")}
            </span>
          )}
        </div>
      </div>
      <div className="mt-2">
        <LanguageSelector className="mb-2" />
      </div>
    </div>
  );
};

export default Authentication;
