import React, { useContext } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomCheckbox, Icon, Icons, StyledButton } from "../../components";
import { colors, TOAST_CONFIG } from "../../imports/constants";
import { AuthContext } from "../../redux-observables/firebase/context";

export default function MnemonicPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, updateUser } = useContext(AuthContext);

  const [mnemonicKnowledge, setMnemonicKnowledge] = useState(false);

  return (
    <div
      //password-modal-container (check justification)
      className="box-border flex flex-col items-center w-full h-full p-4 pt-4 xs:justify-start justify-evenly bg-primaryBackground"
    >
      <span className="my-10 text-18 text-secondaryGrey">
        {t("popup.mnemonic.title")}
      </span>
      <div
        //popup=title
        className="flex-1 text-secondaryGrey"
      >
        <div className="m-auto mb-10 max-w-450">
          <p className="mb-4 text-center text-12">
            {t("popup.mnemonic.subtitle")}
          </p>
          <p className="mb-4 text-center text-12">
            {t("popup.mnemonic.subtitle_2")}
          </p>
          <p className="mt-4 mb-10 text-center text-12">
            {t("popup.mnemonic.mnemonic")}
          </p>
        </div>

        <div
          className="flex items-center justify-between p-3 m-auto my-10 border cursor-pointer border-secondaryGrey max-w-450"
          onClick={() => {
            navigator.clipboard
              .writeText(user.mnemonic)
              .then(() => {
                toast.success(
                  t(`messages.copied`, { string: t("profile.mnemonic") }),
                  TOAST_CONFIG,
                );
              })
              .catch(error => {
                console.log("copy mnemonic error", error);
                toast.error(
                  t(`messages.copy_error`, { string: error }),
                  TOAST_CONFIG,
                );
              });
          }}
        >
          <p>{user.mnemonic}</p>
          <Icon name={Icons.COPY} fill={colors.white}></Icon>
        </div>

        <CustomCheckbox
          className="justify-start w-full m-auto my-4 max-w-450"
          label={
            <span className="text-secondaryGrey text-12">
              {t("popup.transfer.ok")}
            </span>
          }
          onClick={() => {
            setMnemonicKnowledge(!mnemonicKnowledge);
          }}
          checked={mnemonicKnowledge}
        />
      </div>
      <StyledButton
        isDisabled={!mnemonicKnowledge}
        onClick={() => {
          updateUser({ new: false });
          navigate("/redirects?mode=createNFT");
        }}
      >
        {t("popup.mnemonic.saved")}
      </StyledButton>
    </div>
  );
}
