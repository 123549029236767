import { getCarObject } from "../../imports/constants";

interface ICard {
  brand?: string;
  model?: string;
  vin?: string;
  className?: string;
  onClick?: (e?: any) => void;
}

const CarSimpleCard = ({
  brand = "",
  model = "",
  vin = "",
  className = "",
  onClick,
}: ICard) => {
  const carObj = getCarObject(model);

  return (
    <div
      className={`p-2 flex flex-col gap-y-2 rounded-xl w-full box-border bg-black select-none overflow-hidden text-white ${
        onClick ? "cursor-pointer" : ""
      } ${className}`}
      style={{
        boxShadow: "0px 8px 20px -12px rgba(0, 0, 0, 0.75)",
      }}
      onClick={() => onClick?.()}
    >
      <div className="">
        <img
          src={carObj?.img || ""}
          alt={model}
          className="object-cover rounded-xl"
        />
      </div>
      <div className="flex flex-col">
        <span className="text-16 capitalize truncate">
          {brand.toLowerCase()}
        </span>
        <span className="text-16 pb-2 capitalize truncate">{model}</span>
        <span className="text-12 font-medium truncate">{vin}</span>
      </div>
    </div>
  );
};

export default CarSimpleCard;
