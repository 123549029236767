//react
import React, { useEffect } from "react";

//components

import { SplashScreenStepper } from "../../components";
import i18n from "../../imports/i18n";

export default function EasterEgg(props) {
  const minting = false;
  const loader = true;
  const error = false;

  // useEffect(() => {
  //   setInterval(() => {
  //     if(minting)
  //   }, 3000);
  // }, []}

  return (
    <div>
      <SplashScreenStepper
        requestId={"d4ef28c2-7847-4a60-8e0f-ecce0f52ab02"}
        // requestId={null}
        type={minting ? "pending" : loader && !error ? "success" : "failure"}
        message={
          minting
            ? i18n.t("messages.notarization_pending")
            : loader && !error
            ? i18n.t("messages.notarization_success")
            : i18n.t("messages.notarization_failed")
        }
        // buttonClick={() => navigate(`/`)}
        // buttonText={i18n.t("nft_detail.close")}
        text={
          minting
            ? i18n.t("messages.loading")
            : loader && !error
            ? i18n.t("messages.done")
            : i18n.t("messages.failed")
        }
      />
    </div>
  );
}
