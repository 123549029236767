//react
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//material
//import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

//components
import { Icons, Icon, CircleIconButton, LanguageSelector } from "../index";

//style
// import "./drawerMenu.scss";
import { colors } from "../../imports/constants";

//config
import { routes } from "../../imports/config";
import i18n from "../../imports/i18n";

import { CheckIsMobile } from "../../imports/utils";

import { AuthContext } from "../../redux-observables/firebase/context";

import packs from "../../../package.json";
import {
  getCookiePolicy,
  getPrivacyPolicy,
  getTermsAndConditions,
} from "../../redux-observables/firebase/firebase";

export const DrawerMenu = ({ isOpen, toggleDrawer, user }) => {
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("");
  const [termsAndConditionsUrl, setTermsAndConditionsUrl] = useState("");
  const [cookiePolicyUrl, setCookiePolicyUrl] = useState("");
  const navigate = useNavigate();
  const { signOut } = useContext(AuthContext);

  const isMobile = CheckIsMobile();

  useEffect(() => {
    getPrivacyPolicy().then(setPrivacyPolicyUrl);
    getTermsAndConditions().then(setTermsAndConditionsUrl);
    getCookiePolicy().then(setCookiePolicyUrl);
  }, []);

  return (
    <div
      className={
        " fixed overflow-hidden bg-primaryBackground bg-opacity-30 inset-0 transform ease-in-out " +
        (isOpen
          ? " z-10 transition-opacity opacity-100 duration-500 translate-x-0  "
          : " hidden transition-all delay-50 max-w-600 opacity-0 translate-x-100")
      }
      style={isMobile ? {} : { marginLeft: "calc(50% - 300px)" }}
    >
      <div
        className={`h-full relative flex flex-col items-start justify-start bg-primaryBackground w-drawerMenu pb-drawerMenu`}
      >
        <div className="flex items-center justify-start w-full pt-5 pl-5">
          <CircleIconButton
            onClick={() => toggleDrawer(false)}
            icon={
              <Icon
                name={Icons.ARROW}
                fill={colors.white}
                style={{ width: 20, height: 20 }}
              />
            }
            styleType={"secondary"}
          />
        </div>

        <List
          //drawer-list-container
          className="box-border flex flex-col justify-start h-full sm:pb-44"
        >
          {routes
            .filter(el => el.inDrawer)
            .map((route, i) => {
              if ((route.admin && user.role === "admin") || !route.admin) {
                return (
                  <ListItem
                    key={i}
                    onClick={() => {
                      if (!route.disabled) {
                        navigate(route.path);
                        toggleDrawer(false);
                      }
                    }}
                    disabled={route.disabled}
                    style={{ cursor: "pointer" }}
                  >
                    <ListItemIcon className="my-4">
                      <Icon
                        name={Icons[route.drawerIcon]}
                        fill={colors.white}
                        secondaryfill={colors.primary}
                        style={{ width: 30, height: 30 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      className="text-white text-14 font-regular drawer-label-text"
                    >
                      {i18n.t(route.drawerLabel)}
                      {route.notification && (
                        <div
                          //notification
                          className="absolute top-0 w-2 h-2 rounded-50 bg-lightBlue"
                          style={{
                            left: "100px",
                          }}
                        ></div>
                      )}
                    </ListItemText>
                  </ListItem>
                );
              }
            })}

          <ListItem onClick={signOut}>
            <ListItemIcon className="my-4">
              <Icon
                name={Icons.LOGOUT}
                fill={colors.white}
                style={{ width: 30, height: 30 }}
              />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className="text-white cursor-pointer text-14 font-regular drawer-label-text"
              //classes={{ primary: "font-bold" }}
            >
              {i18n.t("drawer.logout")}
            </ListItemText>
          </ListItem>
        </List>
        {/* <div className="absolute bottom-0 flex items-center justify-center w-full pb-1 mb-16">
          <span
            // className={`rounded-50 bg-primaryBackground border-2 shadow-primary text-xl p-2 ${
            //   parseInt(leftOp)
            //     ? "border-green shadow-green text-green "
            //     : "border-red shadow-red text-red"
            // }`}
            className={`text-xl ${
              parseInt(leftOp) ? "text-green " : "text-red"
            }`}
            style={{
              maxWidth: "260px",
            }}
          >
            {i18n.t("home.left_op", { op: leftOp })}
          </span>
        </div> */}
        <LanguageSelector className="m-auto mb-6" />

        <div className="flex flex-col items-center justify-center text-center text-secondary mb-2 mx-auto">
          <div>
            <a href={termsAndConditionsUrl} className="text-11">
              {i18n.t("drawer.terms_and_conditions")}
            </a>
          </div>
          <div>
            <a href={privacyPolicyUrl} className="text-11">
              {i18n.t("drawer.privacy_policy")}
            </a>
          </div>
          <div>
            <a href={cookiePolicyUrl} className="text-11">
              {i18n.t("drawer.cookie_policy")}
            </a>
          </div>
        </div>

        <div className="absolute bottom-0 flex flex-col items-center justify-around w-full h-20 py-2 bg-primary">
          <span className="text-sm font-regular text-secondary">
            {i18n.t("powered_by")}
          </span>

          <span className="text-11 font-regular text-secondary">
            {`v${packs.version}`}
          </span>
        </div>
      </div>
    </div>
  );
};
