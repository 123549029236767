import { ethers } from "ethers";
import { keccak256 } from "ethers/lib/utils";
import { doc, setDoc } from "firebase/firestore";
import {
  CONTRACTS,
  IPFS_GATEWAY,
  NOTARIZATION_STATUS,
} from "../../imports/constants";
import { addFileToIPFS, sdk } from "../../imports/utils";
import { db } from "../../redux-observables/firebase/firebase";

import EthCrypto from "eth-crypto";

const IMAGE_IPFS_CID = "QmSvMpjnD66ZkB7i4CKd4dzSY4ms31CfmNRKJmza7QTifY";

const CAR_DATA = [
  { key: "BRAND", value: "Alfa Romeo" },
  { key: "MODEL", value: "Tonale" },
  { key: "CONFIGURATION", value: "Speciale" },
  { key: "ENGINE", value: "160 Hybrid" },
  // { key: "Date", value: "20/04/2020" },
  { key: "VIN", value: "ZARHA5VP0N3000539" },
  { key: "MILEAGE", value: 45271, unit: "km" },
];

export async function queryCreateNFT(setStep) {
  try {
    //TODO: add file fetch
    const contract = await sdk.getContract(
      process.env[CONTRACTS.CERTIFICATES.env],
      CONTRACTS.CERTIFICATES.abi,
    );
    const tokenId = (await contract.totalSupply()).toNumber() + 1;

    const name = `Tonale_Certificate_${tokenId}`;

    let metaTx = null;
    let requestId = null;

    let dataToStringify = {
      name,
      description: "New Alfa Romeo Tonale",
      image: `${IPFS_GATEWAY}/${IMAGE_IPFS_CID}`,
      // external_url: `${IPFS_GATEWAY}/${zipFileIPFSAddress}`,
    };

    let tokenURI = `${IPFS_GATEWAY}/${await addFileToIPFS(
      dataToStringify,
      `${name}_metadata.json`,
      true,
    )}`;

    // let tokenURI = `${IPFS_GATEWAY}/${name}_metadata.json`;

    metaTx = await sdk.prepareTransaction(
      {
        address: process.env[CONTRACTS.CERTIFICATES.env],
        abi: CONTRACTS.CERTIFICATES.abi,
        name: "Certificates",
        version: "0.0.1",
      },
      "mintToken",
      [
        sdk.getWalletAddress(),
        EthCrypto.encryptWithPublicKey(
          EthCrypto.publicKeyByPrivateKey(sdk.getWallet().privateKey),
          tokenURI,
        ),
        ethers.utils.keccak256(CAR_DATA[4].value),
      ],
    );

    requestId = await sdk.executeAsyncTransaction(metaTx, {
      webhookUrl: `${process.env.REACT_APP_FIREBASE_ENDPOINT}/saveReceipt`,
      verbose: true,
    }); //optionals webhook address
    if (!requestId) {
      return true; //error = true
    }

    await setDoc(doc(db, "nfts", requestId), {
      id: requestId,
      metaTx,
      requestId,
      owner: sdk.getWalletAddress().toLowerCase(),
      createdBy: sdk.getWalletAddress().toLowerCase(),
      status: NOTARIZATION_STATUS.PENDING,
      tokenURI,
      ipfsAddress: IMAGE_IPFS_CID,
      name,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      transfer: { underTransfer: false },
    });
    return { requestId, error: null };
  } catch (error) {
    console.log("got error: ", error);
    return { error, requestId: null };
  }
}
