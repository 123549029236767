//react
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//i18n
import i18n from "../../imports/i18n";

//components
import { Icon, Icons, Stepper, StyledButton } from "../../components/index";
import { OnBoard } from "../../imports/images";

//style
// import "./onboarding.scss";
import { colors } from "../../imports/constants";

//config
import { Mobile, Desktop } from "../../imports/utils";

const Onboarding = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  function handleStep() {
    setStep(step + 1);
  }

  const stepMessages = [
    {
      step: 1,
      title: i18n.t("onboarding.import"),
      message: i18n.t("onboarding.msg_1"),
    },
    {
      step: 2,
      title: i18n.t("onboarding.sign"),
      message: i18n.t("onboarding.msg_2"),
    },
    {
      step: 3,
      title: i18n.t("onboarding.register"),
      message: i18n.t("onboarding.msg_3"),
    },
    {
      step: 4,
      title: i18n.t("onboarding.start"),
      message: i18n.t("onboarding.msg_4"),
    },
  ];

  return (
    <div
      id="onboarding-container"
      //ADD padding: 5% 0 !important;
      className={`w-full h-screen relative overflow-hidden box-border text-center flex flex-col items-center text-secondary bg-primary`}
      style={{ paddingTop: "5%", paddingBottom: "5%" }}
    >
      <Desktop>
        <div
          //TODO
          className={`left-circle-desktop${
            step === 1
              ? "-one"
              : step === 2
              ? "-two"
              : step === 3
              ? "-three"
              : "-three"
          }`}
        ></div>
        <div
          //TODO
          className={`right-circle-desktop${
            step === 1
              ? "-one"
              : step === 2
              ? "-two"
              : step === 3
              ? "-three"
              : "-three"
          }`}
        ></div>
      </Desktop>
      {/* <Mobile>
        <div
          //TODO
          className={`left-circle${
            step === 1
              ? "-one"
              : step === 2
              ? "-two"
              : step === 3
              ? "-three"
              : "-three"
          }`}
        ></div>
        <div
          //TODO
          className={`right-circle${
            step === 1
              ? "-one"
              : step === 2
              ? "-two"
              : step === 3
              ? "-three"
              : "-three"
          }`}
        ></div>
      </Mobile> */}

      {stepMessages.map(
        (el, i) =>
          step === el.step && (
            <div
              key={i}
              //top-box-container
              className="box-border flex flex-col items-center justify-between w-full z-1"
              style={{ height: "70%" }}
            >
              <img
                //onboarding-img
                className="bg-center bg-no-repeat bg-contain"
                src={OnBoard}
                alt=""
              />
              <div
                //title
                className="w-full text-5xl"
              >
                {step === el.step && el.title}
              </div>
              <div
                //message
                className="w-full text-xl font-regular pb-defaultMargin"
              >
                {step === el.step && el.message}
              </div>
            </div>
          ),
      )}

      <div
        //bottom-box-container
        className="flex flex-col items-center w-full z-1 justify-evenly h-1/3"
      >
        {step !== 4 && <Stepper step={step} />}

        {step !== 4 ? (
          <div
            //footer-buttons
            className="flex flex-col items-center justify-around w-4/5 h-32 mt-doubleMargin"
          >
            {/* <Mobile>
              <StyledButton
                rightIcon={
                  <Icon
                    name={Icons.ARROW}
                    fill={colors.primary}
                    style={{
                      transform: "rotate(180deg)",
                    }}
                  />
                }
                styleType="secondary"
                //next-btn
                className="border border-solid border-secondary"
                onClick={handleStep}
              >
                {i18n.t("onboarding.continue")}
              </StyledButton>
            </Mobile> */}
            <Desktop>
              <StyledButton
                rightIcon={
                  <Icon
                    name={Icons.ARROW}
                    fill={colors.primary}
                    style={{
                      transform: "rotate(180deg)",
                    }}
                  />
                }
                styleType="secondary"
                //next-btn
                className="border border-solid border-secondary"
                onClick={() => {
                  if (step === 3) {
                    navigate("/signup");
                  } else {
                    handleStep();
                  }
                }}
              >
                {i18n.t("onboarding.continue")}
              </StyledButton>
            </Desktop>
          </div>
        ) : (
          ""
          //   <Mobile>
          //     <div className="footer-buttons">
          //       <StyledButton
          //         styleType="secondary"
          //         //create-account-btn
          //         className="border border-solid border-secondary"
          //         onClick={() => navigate("/signup")}
          //       >
          //         {i18n.t("onboarding.signup")}
          //       </StyledButton>
          //       <StyledButton
          //         styleType="primary"
          //         //next-btn
          //         className="border border-solid border-secondary"
          //         onClick={() => navigate("/login")}
          //       >
          //         {i18n.t("onboarding.login")}
          //       </StyledButton>
          //     </div>
          //   </Mobile>
        )}
      </div>
    </div>
  );
};

export default Onboarding;
