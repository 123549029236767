/* This page shows details of specified nft */

//react
import React, { useEffect, useReducer, useContext, useState } from "react";

import { downloadFromIPFS, formatTimestamp, sdk } from "../../imports/utils";

//components
import {
  CustomInput,
  // CustomLabel,
  StyledButton,
  BottomDrawer,
  Icon,
  Icons,
  QrcodeReader,
  SplashScreenStepper,
} from "../../components";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

//Lottie
import { Loading } from "../../imports/animations/index";
import Lottie from "react-lottie-player";

//style
import { colors, CONTRACTS, TRANSFER_STEPS } from "../../imports/constants";
// import "./nftDetail.scss";

//i18n
import i18n from "../../imports/i18n";

//carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { useQuery } from "react-query";
import { getUserByAddr, transferNFTCall } from "./queries";

import SwiperCore, { Pagination } from "swiper/core";
import {
  tokenIsOwnedByAddress,
  usePrevious,
  getDecryptTokenURI,
} from "../../imports/utils";

//Firebase
import { useFirestoreDocument } from "@react-query-firebase/firestore";
import { doc } from "firebase/firestore";
import { db } from "../../redux-observables/firebase/firebase";

import { AuthContext } from "../../redux-observables/firebase/context";
import { useNavigate, useParams } from "react-router-dom";
// import { sendEmailVerification } from "firebase/auth";
import TransferModal from "../../components/popup/transferModal/transferModal";

// install Swiper modules
SwiperCore.use([Pagination]);

export default function NftDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, saveAction } = useContext(AuthContext);
  const [step, setStep] = useState(0);

  const documentId = id;

  const [state, dispatch] = useReducer(reducer, {
    openModal: false,
    selectedModal: null,
    receiverPublicKey: null,
    activeQuery: false,
    isOwned: false,
    currentToken: null,
    loader: false,
    selectedNFT: null,
    document: null,
    showScanner: false,
    showMoreInfo: false,
    underTransfer: false,
  });

  const {
    openModal,
    selectedModal,
    receiverPublicKey,
    currentToken,
    loader,
    document,
    showScanner,
    // showMoreInfo,
    error,
    underTransfer,
  } = state;

  function reducer(state, action, data) {
    switch (action.type) {
      case "showMoreInfo":
      case "openModal":
      case "loader":
        return {
          ...state,
          [action.type]: action.payload || !state[action.type],
        };

      default:
        return { ...state, [action.type]: action.payload };
    }
  }

  const {
    data: product,
    isLoading,
    refetch: reloadDoc,
  } = useFirestoreDocument(["nfts", documentId], doc(db, "nfts", documentId));

  const {
    refetch: transferNFT,
    isFetching: transferLoading,
    error: transferError,
  } = useQuery(
    "transferNFT",
    async () => {
      dispatch({ type: "openModal", payload: false });
      dispatch({ type: "underTransfer", payload: true });
      setStep(1);

      const { error } = await transferNFTCall(
        receiverPublicKey,
        document.tokenId,
        { nftId: document.id },
        product.data().carData.vin,
        product.data().carData.brand,
        product.data().carData.model,
      );

      setStep(2);
      if (error) {
        dispatch({ type: "error", payload: error });
      }
      reloadDoc();
      intermediateStep();
    },
    { enabled: false },
  );

  const prevCurrentToken = usePrevious(currentToken);
  const prevLoading = usePrevious(transferLoading);

  const intermediateStep = () => {
    setTimeout(() => setStep(3), 15000);
  };

  useEffect(() => {
    const updateStep = () => {
      if (error || document?.transfer?.underTransfer) {
        reloadDoc();
      } else if (
        !document?.transfer?.underTransfer &&
        sdk.getWalletAddress().toLowerCase() !== document.owner
      ) {
        dispatch({ type: "loader", payload: false });
        setStep(step + 1);
        setTimeout(() => {
          setStep(0);
          navigate("/");
        }, 5000);
      }
    };

    if (underTransfer) {
      updateStep();
    }
  }, [document]);

  useEffect(() => {
    if (!transferLoading && prevLoading) {
      dispatch({ type: "loader", payload: true });
    }
  }, [transferLoading]);

  useEffect(() => {
    if (product) {
      const data = product.data();

      const formatTokenData = async () => {
        dispatch({
          type: "document",
          payload: {
            ...data,
            tokenURI: await getDecryptTokenURI(
              data.tokenId,
              data.carData.model,
            ),
          },
        });
      };
      formatTokenData();
    }
  }, [product]);

  useEffect(() => {
    const checkTokenValues = async () => {
      if (
        document &&
        (currentToken || currentToken === 0) &&
        currentToken !== prevCurrentToken
      ) {
        dispatch({
          type: "isOwned",
          payload: await tokenIsOwnedByAddress(
            currentToken,
            document?.maticTx.to,
            user.wallet,
          ),
        });
      }
    };
    checkTokenValues();
  }, [currentToken, document, prevCurrentToken, user.wallet]);

  const modal = () => {
    let component;

    switch (selectedModal) {
      case "transferNftDrawer":
        component = (
          <div
            className={`text-white text-center flex flex-col items-center w-full pt-2 pb-12 overflow-auto h-2/3 m-auto rounded-t-50 max-w-600 bg-primaryBackground`}
          >
            <span className="py-3 text-md">
              {/* {document.name.replaceAll("_", " ")} */}
              {`${product.data().carData.brand.toUpperCase()} ${product
                .data()
                .carData.model.toUpperCase()}`}
            </span>

            <div className="flex items-center w-full max-w-450 px-30">
              <CustomInput
                onChange={e =>
                  dispatch({
                    type: "receiverPublicKey",
                    payload: e,
                  })
                }
                value={receiverPublicKey}
                placeholder={i18n.t("nft_detail.input_placeholder")}
                containerClass="flex-1"
                icon={
                  <button
                    onClick={() => {
                      dispatch({ type: "showScanner", payload: true });
                      dispatch({ type: "openModal" });
                    }}
                    className={`w-12 h-full flex flex-col justify-center items-center box-border rounded-10 bg-white`}
                  >
                    <Icon
                      name={Icons.CAMERA_CROP}
                      // isSelected={true}
                      fill={colors.primary}
                    />
                  </button>
                }
              />
            </div>

            <StyledButton isDisabled={!receiverPublicKey} onClick={transferNFT}>
              {i18n.t("nft_detail.transfer")}
            </StyledButton>
            <StyledButton
              onClick={() => {
                dispatch({ type: "openModal" });
              }}
              styleType="secondary"
            >
              {i18n.t("nft_detail.cancel")}
            </StyledButton>
          </div>
        );
        break;

      default:
        break;
    }

    return (
      <BottomDrawer
        id="bottom-drawer"
        toggleDrawer={() => dispatch({ type: "openModal" })}
        open={!!openModal}
      >
        {/* {tokenDataLoading || tokenHistoryLoading ? (
          <Loader type={""} />
        ) : ( */}
        {component}
        {/* )} */}
      </BottomDrawer>
    );
  };

  //return transfering && (loading || loader) ? (
  return transferLoading || loader || underTransfer ? (
    <SplashScreenStepper
      steps={TRANSFER_STEPS}
      currentStep={step}
      type={
        transferLoading || loader
          ? "pending"
          : !transferError
          ? "success"
          : "failure"
      }
      message={
        transferLoading || loader
          ? i18n.t("messages.nft_transfer_pending")
          : !transferError
          ? i18n.t("messages.nft_transfer_success")
          : i18n.t("messages.nft_transfer_failed")
      }
    />
  ) : isLoading || !document ? (
    <div
      className={`relative w-40 h-40 m-auto top-1/3`}
      style={{ height: "calc(100vh-80px" }}
    >
      <Lottie play animationData={Loading} />
    </div>
  ) : (
    <div className={`w-full h-full flex flex-col px-30 pt-4 justify-around`}>
      {showScanner && (
        <QrcodeReader
          onClose={() => dispatch({ type: "showScanner", payload: false })}
          onScan={e => {
            dispatch({
              type: "receiverPublicKey",
              payload: e,
            });
            if (e) {
              dispatch({ type: "showScanner", payload: false });
              dispatch({ type: "openModal" });
            }
          }}
        />
      )}
      {/* {document && modal()} */}
      <TransferModal
        opened={!!openModal}
        setCloseModal={() => dispatch({ type: "openModal" })}
      />

      {document.status === "success" && (
        <div className="flex items-center justify-center ">
          <img
            className="border border-secondaryGrey"
            src={
              document?.tokenURI
                ? `${
                    process.env.REACT_APP_IPFS_URL
                  }/${document?.tokenURI.replace("ipfs://", "")}`
                : undefined
            }
            type={"image/png"}
            alt="nft cover"
            style={{ maxHeight: "60vh" }}
            onLoad={() => <Lottie play animationData={Loading} />}
          />
        </div>
      )}
      <div className="flex flex-col pb-4">
        {sdk.getWalletAddress().toLowerCase() === document.owner &&
          !document.transfer.underTransfer && (
            <StyledButton
              onClick={() => {
                dispatch({
                  type: "selectedModal",
                  payload: "transferNftDrawer",
                });
                dispatch({ type: "openModal", payload: document });
                // toast.warning(i18n.t("messages.transfer_warning"),
                // TOAST_CONFIG
                // });
              }}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "10px",
              }}
              // isDisabled={!isOwned}
            >
              {i18n.t("nft_detail.transfer")}
            </StyledButton>
          )}
        <StyledButton
          onClick={() => saveAction("createNFT")}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
          // isDisabled={!isOwned}
        >
          {i18n.t("contracts.update_certificate")}
        </StyledButton>
      </div>
    </div>
  );
}
