import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

// Style
// import "./contracts.scss";
import { colors, IPFS_GATEWAY } from "../../imports/constants";

// i18n
import i18n from "../../imports/i18n";

// Components
import {
  CustomInput,
  StyledButton,
  CustomLabel,
  Icon,
  Icons,
  CircleIconButton,
  NotarizeDocument,
  DocumentImage,
  BottomDrawer,
  LoadingSpinner,
} from "../../components/index";
import { PDFDownloadLink } from "@react-pdf/renderer";

//import
import { downloadFromIPFS, usePrevious } from "../../imports/utils";
import { format } from "date-fns";
import { parse } from "date-fns/esm";
import { getSignersStatus } from "./queries";

const infoChainOptions = [
  {
    label: i18n.t("nft_detail.address_contract"),
    value: "0b8xa...asicx",
  },
  { label: i18n.t("nft_detail.token_id"), value: "1234567" },
  { label: i18n.t("nft_detail.network"), value: "Matic/Polygon" },
];

const Nfts = props => {
  const navigate = useNavigate();
  const {
    loading,
    error,
    location,
    documentList,
    fetchSingleDocument,
    user,
    decryptDocument,
    signDocument,
  } = props;

  const [doc, setDoc] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [activeQuery, setActiveQuery] = useState(false);
  const [password, setPassword] = useState(null);
  const [signaturesStatus, setSignaturesStatus] = useState(null);
  const [loader, setLoader] = useState(false);

  const documentId = location.pathname.replace("/contracts/", "");

  let prevLoading = usePrevious(loading);

  useQuery(
    "MultiSignData",
    async () => {
      setActiveQuery(false);
      try {
        if (doc.multipleSign && doc?.multiSignAddress) {
          const signers = await getSignersStatus(
            user.wallet,
            doc.multiSignAddress,
            doc.signatures,
          );

          setSignaturesStatus(signers);
          setIsSigned(
            doc.owner === user.uid
              ? true
              : signers?.find(sign => sign.address === user.wallet.address)
                  ?.status,
          );
        }
      } catch (err) {
        console.log(err);
      }
    },
    { enabled: activeQuery },
  );

  useEffect(() => {
    const doc = documentList?.find(el => el._id === documentId);
    if (doc) {
      setDoc(doc);
      if (doc.multipleSign) {
        setActiveQuery(true);
      }
    } else {
      fetchSingleDocument({ docId: documentId });
    }
  }, [documentList, documentId]);

  useEffect(() => {
    if (!loading && prevLoading) {
      setActiveQuery(true);
    }
    if (!loading && prevLoading && !error) {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
      }, 6000);
    }
    if (!loading && prevLoading && error) {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    }
  }, [loading]);

  const modal = () => {
    return (
      <BottomDrawer
        id="bottom-drawer"
        open={openModal}
        toggleDrawer={() => setOpenModal(!openModal)}
      >
        <div
          //info-chain-container
          className={`text-center flex flex-col items-center justify-center w-full h-full pb-12 `}
        >
          <div
            //info-chain-options
            className="w-full"
          >
            {infoChainOptions.map((opt, i) => {
              return (
                <CustomLabel
                  key={i}
                  label={opt.label}
                  textComponent={opt.value}
                />
              );
            })}
          </div>

          <StyledButton
            onClick={() => setOpenModal(!openModal)}
            styleType="secondary"
          >
            {i18n.t("nft_detail.close")}
          </StyledButton>
        </div>
      </BottomDrawer>
    );
  };

  return (
    <>
      {modal()}
      {loading || !doc ? (
        <LoadingSpinner />
      ) : doc?.private && !doc?.nft && !doc.accessGranted ? (
        <div
          id="isPrivate"
          className={`w-full h-screen text-center flex flex-col items-center justify-evenly`}
        >
          <div
            //text-box
            className={`text-black`}
          >
            {/* <h1 className="title">{doc.name}</h1> */}
            <h2
              //sub-title
              className="text-xl"
            >
              {i18n.t("contracts.private")}
            </h2>
            <p
              //description
              className="text-xl font-normal"
            >
              {i18n.t("contracts.description")}
            </p>
          </div>
          {doc.sharedCryptedData ? (
            <>
              <CustomInput
                type={"password"}
                placeholder={i18n.t("contracts.input_password")}
                onChange={setPassword}
              />

              <StyledButton
                onClick={() => {
                  decryptDocument({
                    docId: doc._id,
                    password,
                  });
                }}
              >
                {i18n.t("contracts.access_document")}
              </StyledButton>
            </>
          ) : (
            <div
              //text-box
              className={`text-black`}
            >
              <p
                //description
                className="text-xl font-normal"
              >
                {i18n.t("contracts.no_crypted_data")}
              </p>
            </div>
          )}
          <StyledButton styleType="secondary" onClick={() => navigate(-1)}>
            {i18n.t("contracts.go_back")}
          </StyledButton>
        </div>
      ) : (
        <div className={`text-center flex flex-col justify-between h-screen`}>
          <div className="flex flex-col items-center w-full mb-12">
            <div
              className={`w-full text-4xl relative pt-5 pb-5 pl-defaultMargin pr-defaultMargin`}
            >
              <CircleIconButton
                size="small"
                onClick={() => navigate("/")}
                icon={
                  <Icon
                    name={Icons.ARROW}
                    fill={colors.primary}
                    style={{
                      width: 25,
                      height: 20,
                    }}
                  />
                }
                styleType="secondary"
                className="absolute"
              />
              <h1>{doc.name}</h1>
            </div>
            <div
              // className={`document-description ${
              //     !doc.description && "no-description"
              // }`}
              className={`w-full text-left text-black ${
                !doc.description ? "italic" : ""
              }`}
              style={{
                marginBlockStart: "1em",
                marginBlockEnd: "1em",
                marginInlineStart: "0px",
                marginInlineEnd: "0px",
              }}
            >
              <p>{doc.description || i18n.t("contracts.no_description")}</p>
            </div>

            <div
              //labels-container
              className="flex flex-col items-center w-full pt-5 pb-5"
            >
              {doc.onlyHash && (
                <>
                  {doc.text && (
                    <CustomLabel
                      label={i18n.t("contracts.text")}
                      tooltipText={i18n.t("contracts.text_tooltip")}
                      textComponent={doc.text}
                    />
                  )}
                  <CustomLabel
                    label={i18n.t("contracts.hash")}
                    tooltipText={i18n.t("contracts.hash_tooltip")}
                    textComponent={doc.hash}
                    copy={"hash"}
                  />
                </>
              )}
              <CustomLabel
                label={i18n.t("contracts.blockchain_certification")}
                tooltipText={i18n.t("contracts.blockchain_certification")}
                textComponent={
                  <div
                    //tx-status-container
                    className="flex flex-col items-start w-full"
                  >
                    <div
                      //status-container
                      className="flex items-center justify-start w-full pb-2"
                    >
                      <Icon
                        name={Icons.HEXAGON}
                        fill={
                          colors[
                            doc.maticStatus === 1
                              ? "primary"
                              : "transparentPrimary"
                          ]
                        }
                        style={{
                          width: 30,
                          height: 30,
                        }}
                      />
                      {doc.maticStatus === 1 ? (
                        <a
                          //text
                          className={"text-base pl-2 text-black"}
                          href={`${process.env[`REACT_APP_MATIC_EXPLORER`]}${
                            doc.maticTx?.transactionHash
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {`${i18n.t("matic")} - ${format(
                            parse(doc.createdAt, "t", new Date()),
                            "dd/MM/yy",
                          )}`}
                        </a>
                      ) : (
                        <div //text
                          className={"text-base pl-2 text-black"}
                        >
                          {`${i18n.t(`tx.${doc.maticStatus}`)} - ${i18n.t(
                            "matic",
                          )}`}
                        </div>
                      )}
                      {/* <div className="text">
                                             {doc.maticStatus !== 1
                                                ? i18n.t(
                                                      `tx.${doc.maticStatus}`,
                                                  )
                                                : doc.matDate}{" "}
                                            - {i18n.t("matic")}
                                        </div> */}
                    </div>
                    <div
                      //status-container
                      className="flex items-center justify-start w-full pb-2"
                    >
                      <Icon
                        name={Icons.HEXAGON}
                        fill={
                          colors[
                            doc.ethStatus === 1
                              ? "primary"
                              : "transparentPrimary"
                          ]
                        }
                        style={{
                          width: 30,
                          height: 30,
                        }}
                      />
                      {/* <div className="text">
                                            {doc.ethStatus !== 1
                                                ? i18n.t(`tx.${doc.ethStatus}`)
                                                : doc.ethDate}{" "}
                                            - {i18n.t("ethereum")}
                                        </div> */}
                      {doc.ethStatus === 1 ? (
                        <a
                          //text
                          className={"text-base pl-2 text-black"}
                          href={`${process.env[`REACT_APP_ETH_EXPLORER`]}${
                            doc.ethTx.transactionHash
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {`${i18n.t("ethereum")} - ${format(
                            parse(doc.updatedAt, "t", new Date()),
                            "dd/MM/yy",
                          )}`}
                        </a>
                      ) : (
                        <div //text
                          className={"text-base pl-2 text-black"}
                        >
                          {`${i18n.t(`tx.${doc.ethStatus}`)} - ${i18n.t(
                            "ethereum",
                          )}`}
                        </div>
                      )}
                    </div>
                  </div>
                }
                topRightItem={
                  (doc.ethStatus === 0 || doc.maticStatus === 0) && (
                    <Icon
                      name={Icons.WAITING}
                      fill={colors.primary}
                      style={{
                        width: 30,
                        height: 30,
                      }}
                    />
                  )
                }
              />

              {doc.token && (
                <CustomLabel
                  label={i18n.t("contracts.token")}
                  tooltipText={i18n.t("contracts.token")}
                  textComponent={doc.token}
                  styleType="primary"
                  topRightItem={
                    <CircleIconButton
                      onClick={() => setOpenModal(!openModal)}
                      icon={<Icon name={Icons.SEARCH} className="icon" />}
                      style={{
                        height: "30px",
                        width: "30px",
                      }}
                      styleType="primary"
                    />
                  }
                />
              )}

              {doc.multipleSign && (
                <>
                  <CustomLabel label={i18n.t("contracts.signers")} />
                  <div
                    //signatures
                    className={`w-full flex max-w-450 flex-col items-start justify-around`}
                  >
                    {signaturesStatus?.map(sign => (
                      <div
                        className={"sign flex w-full justify-between"}
                        key={sign.address}
                      >
                        <p>{`${sign.signer} - @${sign.username}`}</p>

                        <Icon
                          name={sign.status ? Icons.VERIFIEDMARK : Icons.CROSS}
                          style={{
                            fill: sign.status
                              ? colors.primary
                              : colors.lightGrey,
                            width: 30,
                            height: 25,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            {doc.ipfsAddress && (
              <DocumentImage
                privateKey={
                  doc.private && doc.owner !== user.uid && !doc.nft
                    ? password
                    : doc.private && !doc.nft
                    ? user.wallet.privateKey
                    : false
                }
                ipfsAddress={doc.qrCodeIpfsAddress || doc.ipfsAddress}
                type={doc.fileType}
                src={`${IPFS_GATEWAY}/${
                  doc.qrCodeIpfsAddress || doc.ipfsAddress
                }`}
                isOwner={doc.owner === user.uid}
              />
            )}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: 50,
            }}
          >
            <>
              {console.log(!!doc?.pendingSign, doc?.multiSignAddress, doc)}
              {!isSigned && doc.multipleSign && (
                <StyledButton
                  styleType={"secondary"}
                  isDisabled={!!doc?.pendingSign || !doc?.multiSignAddress}
                  onClick={() => {
                    console.log(doc?.pendingSign);
                    signDocument({
                      doc,
                      address: doc.multiSignAddress,
                    });
                  }}
                >
                  {i18n.t("contracts.sign")}
                </StyledButton>
              )}
              <PDFDownloadLink
                fileName={doc?.name}
                document={<NotarizeDocument notarization={doc} />}
                style={{
                  width: "100%",
                  maxWidth: "350px",
                  textDecoration: "none",
                  // marginTop: 30,
                }}
              >
                <StyledButton>
                  {i18n.t("contracts.download_notarization")}
                </StyledButton>
              </PDFDownloadLink>
              {!doc.onlyHash &&
                !!doc.ipfsAddress &&
                (!doc.isPrivate ||
                  (doc.isPrivate && doc.owner !== user.uid)) && (
                  <StyledButton
                    styleType={"primary"}
                    onClick={() =>
                      downloadFromIPFS(
                        doc.fileName,
                        doc.private ? user.wallet.privateKey : false,
                        doc.ipfsAddress,
                      )
                    }
                  >
                    {i18n.t("contracts.download_document")}
                  </StyledButton>
                )}
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default Nfts;
