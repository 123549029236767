import * as Yup from "yup";

export const recoveryPasswordValidation = Yup.object({
  newPassword: Yup.string()
    .min(8, "password_min_char")
    .max(16, "password_max_char")
    .required("insert_password"),
  seed: Yup.string().test(
    "",
    "insert_mnemonic",
    str => str && str.split(" ").length === 12,
  ),
});
