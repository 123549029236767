//react
import React from "react";

//material
import TextField from "@material-ui/core/TextField";

//style
// import "./messageBox.scss";

//responsiveness

export function MessageBox({
  charLimit = null,
  initialValue = "",
  onChange = null,
  placeholder = "",
  styleType = "primary",
  rows = 8,
}) {
  const [value, changeValue] = React.useState(initialValue);
  const [char, changeCharValue] = React.useState(0);

  const handleChange = event => {
    if (!charLimit) {
      changeValue(event.target.value);
      onChange && onChange(event.target.value);
    } else {
      if (event.target.value.length <= charLimit) {
        changeCharValue(event.target.value.length);
        changeValue(event.target.value);
        onChange && onChange(event.target.value);
      }
    }
  };

  return (
    <div className={`w-full relative`}>
      {/* <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth={true}
                multiline={true}
                maxRows={rows}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
            /> */}
      <textarea
        className="box-border w-full h-10 px-4 py-4 text-sm font-normal text-black outline-none false bg-lightGrey rounded-10"
        style={{ minHeight: "150px" }}
        placeholder={placeholder}
        rows={rows}
        onChange={handleChange}
      />

      {charLimit && (
        <div
          //charLimitCounter
          className={`text-base font-regular absolute bottom-2.5 right-2.5 ${
            styleType === "primary" ? "" : "text-grey "
          } `}
        >
          {char}/{charLimit}
          {char === charLimit && "!"}
        </div>
      )}
    </div>
  );
}
