import { CONTRACTS } from "../../imports/constants";
import { getContract } from "../../imports/utils";

export async function getSignersStatus(wallet, address, signatures) {
  const multiSignContract = getContract(
    { address, ...CONTRACTS.PABLOCK_MULTISIGN },
    wallet,
  );

  let signaturesStatus = [];

  for (const sign of signatures) {
    signaturesStatus.push({
      signer: `${sign.name} ${sign.surname}`,
      address: sign.address,
      username: sign.username,
      status: await multiSignContract.getSignerStatus(sign.address),
    });
  }

  return signaturesStatus;
}
