//react
import React, {  useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

//formik
import { Formik } from "formik";

//config
import {
  // signupFormStepOne,
  signupFormStepTwo,
  // signupValidationStepOne,
  signupInitialValues,
  signupValidationStepTwo,
  checkFieldCompletition,
} from "./formValidation";

//i18n
import i18n from "../../imports/i18n";

//components
import {
  StyledButton,
  CustomInput,
  SplashScreen,
  // CustomCheckbox,
  // Icons,
  // Icon,
} from "../../components/index";
import AlfaRomeoLogo from "../../components/icons/alfaRomeoLogo";
import { AuthContext } from "../../redux-observables/firebase/context";
// import { colors } from "../../imports/constants";

const Signup = props => {
  const navigate = useNavigate();

  const { user, registerUser, isLoading: loading } = useContext(AuthContext);

  // const [show, setShow] = useState(false);
  // const [isCompany, setIsCompany] = useState(false);
  // const [savedValues, setSavedValues] = useState(null);

  // const handleClick = () => {
  //   setShow(!show);
  // };

  useEffect(() => {
    // console.log("user logged: ", user?.emailVerified);
    if (user?.emailVerified) {
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    }
  }, [user, navigate]);

  return loading ? (
    <SplashScreen
      type={"pendingSignup"}
      text={i18n.t("messages.account_creation")}
      //message={i18n.t("messages.please_wait")}
    />
  ) : (
    // ) : user?.emailVerified ? (
    //   <div className="box-border flex flex-col items-center justify-center w-full px-30 my-30 color-primary">
    //     {/* <span className="text-5xl font-semibold text-primary">
    //       {i18n.t("signup.registration")}
    //     </span> */}

    //     <span className="text-2xl text-center text-text">
    //       {i18n.t("signup.email_alert")}
    //     </span>

    //     <Icon
    //       name={Icons.EMAIL_SENT}
    //       style={{
    //         width: 250,
    //         height: 250,
    //       }}
    //       fill={colors.pink}
    //     />
    //     <p className="text-2xl text-center text-text">
    //       {i18n.t("signup.click_link")}
    //     </p>
    //     <p className="text-2xl text-center text-text">
    //       {i18n.t("signup.redirect")}
    //     </p>
    //   </div>
    <div className="box-border flex flex-col items-center justify-around w-full pb-30 color-primary">
      {/* <div className="text-5xl font-semibold text-primary pb-60">
        {i18n.t("signup.registration")}
      </div> */}
      <div className="flex flex-col items-center justify-center ">
        <AlfaRomeoLogo width={120} height={120} className="my-6" />
      </div>

      <Formik
        initialValues={signupInitialValues["private"]}
        validationSchema={signupValidationStepTwo["private"]}
        onSubmit={values => {
          // if (!show) {
          //   handleClick();
          // } else {
          // setSavedValues(values);

          registerUser(values);
          // }
        }}
      >
        {({ handleSubmit, values, errors, setFieldValue }) => (
          <>
            <div className="flex flex-col items-center justify-center w-full px-30">
              {/* {!show && (
                <DoubleSwitch
                  leftValue={i18n.t("selector.private")}
                  rightValue={i18n.t("selector.company")}
                  value={isCompany}
                  onChange={setIsCompany}
                />
              )} */}
              {/* {signupFormStepOne["private"].map(
                (el, i) =>
                  !show && (
                    <CustomInput
                      key={i}
                      type={el.type}
                      label={el.label}
                      formName={el.formName}
                      value={
                        // el.type === "check"
                        //     ? check
                        //     :
                        values[el.formName] || false
                      }
                      placeholder={el.placeholder || null}
                      onChange={value =>
                        // el.type === "check"
                        //     ? setCheck
                        //     :
                        setFieldValue(el.formName, value)
                      }
                      errors={errors[el.formName]}
                    />
                  ),
              )} */}
              {/* {show && */}
              {signupFormStepTwo.map((el, i) =>
                el.type !== "onlyText" ? (
                  <CustomInput
                    key={i}
                    type={el.type}
                    label={el.label}
                    value={
                      // el.type === "check"
                      // ? check
                      // :
                      values[el.formName]
                    }
                    placeholder={el.placeholder || null}
                    onChange={value =>
                      // el.type === "check"
                      //     ? setCheck
                      //     :
                      setFieldValue(el.formName, value)
                    }
                    errors={errors[el.formName]}
                    link={el.link}
                    modalMessage={el.modalMessage}
                    doubleCheck={el.doubleCheck}
                  />
                ) : (
                  <div
                    key={i}
                    className="flex flex-col w-full my-4 text-left max-w-450 align-center text-secondaryGrey"
                  >
                    <span className="my-2 text-xs">
                      {i18n.t("signup.disclaimer1")}
                    </span>
                    <span className="flex flex-col m-auto text-sm">{`${i18n.t(
                      "signup.signup_as",
                    )}:`}</span>
                    <span className="flex flex-col m-auto text-sm">
                      {(values[el.formName] = "test@test.test")}
                    </span>

                    {/* {el.label} */}
                  </div>
                ),
              )}
              <span className="my-2 text-xs text-secondaryGrey max-w-450">
                {i18n.t("signup.disclaimer2")}
              </span>
              {/* {!show && (
                <div className="flex flex-col items-start justify-center w-full my-3 max-w-450">
                  {!values.company && (
                    <div>
                      <CustomInput
                        //type="multiline"
                        label={`${i18n.t("selector.company")}*`}
                        formName={"companyName"}
                        value={values.companyName}
                        onChange={e => setFieldValue("companyName", e)}
                        placeholder={i18n.t("forms_placeholders.company_name")}
                        errors={errors["companyName"]}
                      />
                      <span className="text-sm text-primary">
                        {i18n.t("forms.company_hint")}
                      </span>
                    </div>
                  )}
                  <CustomCheckbox
                    label={i18n.t("forms.company_check")}
                    checked={values.company}
                    onClick={() => setFieldValue("company", !values.company)}
                  />
                </div>
              )} */}
            </div>
            <div
              className="flex flex-col items-center justify-around w-11/12 h-1/3 mt-60"
              style={{
                maxHeight: "230px",
              }}
            >
              <StyledButton
                styleType="primary"
                onClick={handleSubmit}
                isDisabled={
                  !isEmpty(errors) ||
                  checkFieldCompletition(
                    values,
                    "private", //!show ? 1 :
                    2,
                  )
                }
              >
                {i18n.t("signup.signup")}
                {/* {!show ? i18n.t("signup.next_step") : i18n.t("signup.signup")} */}
              </StyledButton>
              {/* <div className="flex items-center justify-between w-full my-4 text-base text-center max-w-450 color-primary">
                <div className="w-1/3 h-0 mt-1 border border-primary"></div>
                <span className="text-text">{i18n.t("signup.otherwise")}</span>
                <div className="w-1/3 h-0 mt-1 border border-primary"></div>
              </div>
              <StyledButton
                styleType="secondary"
                onClick={() => {
                  navigate("/login");
                  // !show ? navigate("/login") : handleClick();
                }}
              >
              {i18n.t("signup.login")}*/}
              {/* {!show ? i18n.t("signup.login") : i18n.t("signup.prev_step")} */}
              {/*</StyledButton> */}
            </div>
          </>
        )}
      </Formik>
      {/* <div className="signup-links">
                <div className="signup-link" onClick={() => navigate("/")}>
                    {i18n.t("forms.issues")}
                </div>
                <div className="signup-link" onClick={() => navigate("/")}>
                    {i18n.t("forms.forgot")}
                </div>
            </div> */}
    </div>
  );
};

export default Signup;
