//formik
import * as Yup from "yup";
import { isEqual } from "lodash";
import { pick } from "lodash";

export const signupFormStepTwo = [
  {
    label: "forms.email",
    type: "onlyText",
    formName: "email",
    placeholder: "forms_placeholders.email",
  },
  {
    label: "forms.password",
    type: "password",
    formName: "password",
    placeholder: "forms_placeholders.password",
  },
  {
    label: "forms.repeat_password",
    type: "password",
    formName: "repeatPassword",
    placeholder: "forms.repeat_password",
  },
];

export const signupInitialValues = {
  email: "",
  password: "",

  repeatPassword: "",
};

export const signupInitialValuesForCheck = {
  email: "",
  password: "",
  repeatPassword: "",
};

export const signupValidationStepTwo = Yup.object({
  // email: Yup.string().email().required("inserire il nome"),
  password: Yup.string()
    .min(8, "password_min_char")
    .max(16, "password_max_char")
    .required("insert_password"),

  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "password_match")
    .required("insert_password"),
});

export function checkFieldCompletition(values) {
  const obj = signupInitialValuesForCheck;

  return isEqual(pick(values, Object.keys(obj)), obj);
}
