/* Page showing specific user data */

//react
import React, { useEffect, useState } from "react";

//components
import { CustomLabel, DocumentImage } from "../../components";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

//Lottie
import { Loading } from "../../imports/animations/index";
import Lottie from "react-lottie-player";

// import "./nftDetail.scss";

//i18n
import i18n from "../../imports/i18n";

//carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";

//Firebase
import {
  useFirestoreQueryData,
  useFirestoreDocument,
} from "@react-query-firebase/firestore";
import { query, collection, where, doc } from "firebase/firestore";
import { db } from "../../redux-observables/firebase/firebase";

// install Swiper modules
// SwiperCore.use([Pagination]);

const UserDetails = props => {
  const { match } = props;

  const userId = match.params.id;

  const details = {
    email: "profile.email",
    username: "profile.username",
    createdAt: "profile.profile_date",
    owned_nft: "profile.owned_nft",
    owned_nft_names: "profile.owned_nft_names",
    address: "profile.address",
  };

  //const { user, recoverWallet } = useContext(AuthContext);

  const { data: userInfos, isLoading } = useFirestoreDocument(
    ["users", userId],
    doc(db, "users", userId),
  );

  const { data: nfts, isLoading: fetchingNfts } = useFirestoreQueryData(
    ["requestId"],
    query(collection(db, "nfts"), where("owner", "==", userId)),
  );

  const [userDetails, setUserDetails] = useState(userInfos);

  useEffect(() => {
    if (userInfos && nfts) {
      const nfts_names = nfts.map(key => {
        return key.cellData.name;
      });

      const infos = {
        ...userInfos.data(),
        address: JSON.parse(userInfos.data().wallet).address,
        owned_nft: nfts.length,
        owned_nft_names: nfts_names.join(", "),
      };
      setUserDetails(infos);
    }
  }, [userInfos, nfts]);

  // useEffect(() => {
  //   if (documentId) {
  //     fetchSingleDocument({ docId: documentId });
  //   }
  // }, []);

  return isLoading || !userDetails ? (
    <div className={`w-full relative`} style={{ height: "calc(100vh-80px" }}>
      <Lottie className="loading-spinner" play animationData={Loading} />
    </div>
  ) : (
    <div className={`w-full relative`} style={{ height: "calc(100vh-80px" }}>
      <div
        //img-container
        className="flex items-center justify-center w-full mb-8"
      >
        <DocumentImage src={userDetails.propic} type={"image/svg+xml"} />
      </div>

      {Object.keys(details).map(function (key) {
        return (
          <CustomLabel
            key={key}
            styleType={"tertiary"}
            label={`${i18n.t(details[key])}`}
            textComponent={userDetails[key]}
            copy={key === "address"}
          />
        );
      })}
    </div>
  );
};

export default UserDetails;
