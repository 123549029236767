/* Splash Screen page: after every transaction on blockchain, 
this splashscreen let user know if it was successful or not */

//react
import React, { useEffect } from "react";

//components
import AlfaRomeoLogo from "../../icons/alfaRomeoLogo";
import { Stepper, Step, useStepper } from "react-progress-stepper";
import { Icons, Icon } from "../../index";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";

//animations
import { Failure, Loading, Success } from "../../../imports/animations/index";
import Lottie from "react-lottie-player";
import { colors, TOAST_CONFIG } from "../../../imports/constants";

import CheckStep from "../../checkStep/checkStep";
import { toast } from "react-toastify";
import i18n from "../../../imports/i18n";

const SplashScreenStepper = ({
  steps,
  type = "success",
  text,
  message,
  currentStep = 0,
}) => {
  const isSuccess = type === "success";
  const isFailure = type === "failure";

  return (
    <>
      <div className={`absolute top-0 left-0 z-10 `}>
        <div
          id="topbar"
          className="fixed top-0 z-10 flex flex-row items-center justify-between w-full h-12 px-4 pt-0 pb-0 mx-auto mb-0 max-w-600 bg-secondaryBackground"
        >
          <span className="text-lightGrey">{i18n.t("notarize.title")}</span>
          <Icon
            name={Icons.ALFA_ROMEO_LOGO_BI}
            fill={colors.white}
            secondaryfill={colors["secondary"]}
            style={{ width: 26, height: 26 }}
          />
        </div>
        <div
          className={`flex flex-col justify-between items-center relative text-center text-secondary max-w-600 w-screen h-screen px-10 pt-16 py-0 ${
            isSuccess
              ? " bg-primaryBackGround"
              : isFailure
              ? "bg-red"
              : "bg-primaryBackground"
          }`}
        >
          <span>{i18n.t("notarize.disclaimer_timer")}</span>
          <div className="w-30 h-30">
            <Lottie
              loop={isSuccess ? false : isFailure ? false : true}
              play
              animationData={
                isSuccess ? Success : isFailure ? Failure : Loading
              }
            />
          </div>
          <div
            className="flex flex-col items-center text-2xl "
            style={{ lineHeight: "30px" }}
          >
            {/* <p style={{ margin: "0" }}>{text}</p> */}
            <p className="text-lg font-normal"> {message}</p>
          </div>
          <div className="w-full text-2xl ">
            <CheckStep steps={steps} currentStep={currentStep} />
          </div>

          <div className="flex flex-col items-center justify-center mt-0">
            <Icon
              name={Icons.ALFA_ROMEO_LOGO_BI}
              fill={colors.secondaryText}
              secondaryfill={colors.secondaryText}
              style={{ width: 20, height: 20 }}
            />

            <div className="mb-2 text-11 text-secondaryGrey">
              <span>Powered By </span>
              <span className="font-medium">{`KNOBS`}</span>
            </div>
          </div>
        </div>
      </div>
      {currentStep <= 2 && (
        <div id="certificate" className="absolute top-0 z-0" />
      )}
    </>
  );
};

export default SplashScreenStepper;
