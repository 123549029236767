// import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import {
  // NOTARIZATION_STATUS,
  TOAST_CONFIG,
  getCarObject,
} from "../../imports/constants";
import { addFileToIPFS, sdk } from "../../imports/utils";
import { createRoot } from "react-dom/client";
import html2canvas from "html2canvas";
import { ethers } from "ethers";
import { NftCertificate } from "../../components";
import axios from "axios";
import { toast } from "react-toastify";
import i18n from "../../imports/i18n";
import EthCrypto from "eth-crypto";
import { auth } from "../../redux-observables/firebase/firebase";
import { Client } from "@indid/indid-core-sdk";

import { waitForTransactionStatusChanges } from "../../helpers/waitForTransactionStatusChanges";

export async function queryCreateNFT(car, userId) {
  // console.log("queryCreateNFT start");
  const { brand, model, vin, odometer } = car;

  // console.log({ brand, model, vin, odometer });

  try {
    const { abi, certificate: address } = getCarObject(model);
    // console.log("abi, address: ", abi, address);

    if (!abi || !address) {
      throw new Error("Unexistent car");
    }

    const carContract = sdk.getContract(address, abi);

    // console.log("carContract: ", carContract);

    // set mock vin to test mintCertificate function
    // vin = "vin_nuovissimo_123456";

    const hashedVin = ethers.utils.keccak256(Buffer.from(vin));

    // console.log("hashedVin: ", hashedVin);

    const tokenId = parseInt(
      (await carContract.getCarTokenId(hashedVin)).toString(),
    );

    // console.log("tokenId: ", tokenId);

    const carData = { brand, model, vin, mileage: odometer };

    const publKey = EthCrypto.publicKeyByPrivateKey(sdk.getPrivateKey());

    // console.log("publicKey: ", publKey);

    const cert = await createCertificate(carData);

    // console.log("cert: ", cert);

    const certificate = EthCrypto.cipher.stringify(
      await EthCrypto.encryptWithPublicKey(publKey, cert),
    );

    // console.log("certificate: ", certificate);

    let dataToStringify = {
      name: `${brand} ${model}`,
      description: `${model} Certificate`,
      image: certificate,
    };

    let tokenURI = await addFileToIPFS(
      dataToStringify,
      `${address}_${vin}`,
      true,
    );

    //console.log("tokenURI: ", tokenURI);

    tokenURI = `ipfs://${tokenURI}`;

    // if (false) {
    if (tokenId) {
      /* UPDATE */
      // console.log("Updating document ...");
      const userToken = await auth.currentUser.getIdToken();

      const params = {
        model,
        tokenURI,
        hashedVin,
        carData,
        userId,
      };

      //console.log("params: ", params);

      const { data } = await axios.post(
        `${process.env.REACT_APP_FIREBASE_ENDPOINT}/updateCertificate`,
        params,
        { headers: { authorization: `Bearer ${userToken}` } },
      );

      const { txId } = data;

      // console.log("TRY data", data);
      // console.log("TRY txId", txId);

      // console.log("waitForTransactionStatusChanges before");

      const statusResult = await waitForTransactionStatusChanges(txId);

      //console.log("waitForTransactionStatusChanges after: ", statusResult);

      return { status: statusResult };
    } else {
      /* MINT */
      //console.log("minting document ...");
      const userToken = await auth.currentUser.getIdToken();

      const params = {
        model,
        tokenURI,
        hashedVin,
        carData,
        userId,
      };

      //console.log("params: ", params);

      const { data } = await axios.post(
        `${process.env.REACT_APP_FIREBASE_ENDPOINT}/mintCertificate`,
        params,
        { headers: { authorization: `Bearer ${userToken}` } },
      );

      const { txId } = data;

      // console.log("TRY data", data);
      // console.log("TRY txId", txId);

      // console.log("waitForTransactionStatusChanges before");

      const statusResult = await waitForTransactionStatusChanges(txId);

      // console.log("waitForTransactionStatusChanges after: ", statusResult);

      return { status: statusResult };
    }
  } catch (error) {
    console.log("[queryCreateNFT] error", error);
    throw error;
  }
}

function waitForElement(id) {
  return new Promise(resolve => {
    const observer = new MutationObserver((mutations, obs) => {
      // console.log("observer running");
      const el = document.getElementById(id);
      if (el) {
        // console.log("observer closed");
        resolve(el);
        obs.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
/**
 * input: json containing car data
 * output: HTML image or error
 * role: it has to create the html image of certificate
 */
async function createCertificate(carData) {
  renderCertificate(carData);

  //console.log("certificate rendered");

  // aspetto che l'elemento NftCertificate venga renderizzato
  const el = await waitForElement("nftCertificate");
  // console.log("el", el);

  const canvas = await html2canvas(el);
  //console.log("canvas", canvas);

  const image = canvas.toDataURL("image/png", 1.0);
  //console.log("image", image);

  const uri = await getIpfsUri(image);
  //console.log("uri", uri);

  return uri;
}

/**
 * input: json containing car data
 * output: certificate html code
 * role: it has to create the certificate's template
 */
function renderCertificate(carData) {
  const certificate = <NftCertificate carData={carData} />;
  const container = document.getElementById("certificate");

  // console.log("container: ", container);
  // console.log("certificate: ", certificate);

  if (container !== null) {
    const root = createRoot(container);
    // console.log("root: ", root);

    root.render(certificate);
  }
}

/**
 *
 * create formdata to be sent to IPFS
 */
async function getIpfsUri(img) {
  const file = await urltoFile(img, "certificate.png", "image/png").then(
    function (file) {
      return file;
    },
  );

  const formData = new FormData();
  formData.append("file", file);
  const cid = await addFileToIPFS(formData, "certificate");
  return `ipfs://${cid}`;
}

async function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export async function queryCarsData(userId) {
  let res = [];
  const region = localStorage.getItem("region");

  if (auth.currentUser) {
    try {
      const userToken = await auth.currentUser.getIdToken();

      const cars = await axios.get(
        `${process.env.REACT_APP_FIREBASE_ENDPOINT}/getVehicleList?userId=${userId}&region=${region}`,
        { headers: { authorization: `Bearer ${userToken}` } },
      );

      // console.log("cars.data: ", cars.data);

      res = cars.data;

      // add mock VIN to test setCarOwner function
      // res.push({
      //   brand: "ALFA ROMEO",
      //   model: "620",
      //   modelDescription: "Giulia MY20",
      //   version: "PR2",
      //   series: "1",
      //   vin: "prova_ciccio",
      //   odometer: "100",
      // });
    } catch (err) {
      console.log(err);
      toast.error(i18n.t(`errors.${err}`), TOAST_CONFIG);
    }
    return res;
  } else {
    return "logout";
  }
}

export async function setCarProperty(userId, address, vin, model) {
  const region = localStorage.getItem("region");

  if (auth.currentUser) {
    try {
      const userToken = await auth.currentUser.getIdToken();

      const params = {
        userId,
        address,
        vin,
        model,
        region,
      };

      // console.log("params: ", params);

      // console.log("setCarOwner CALLED");

      const { data } = await axios.post(
        `${process.env.REACT_APP_FIREBASE_ENDPOINT}/setCarOwner`,
        params,
        { headers: { authorization: `Bearer ${userToken}` } },
      );

      const { txId } = data;

      // console.log("TRY data", data);
      // console.log("TRY txId", txId);

      // console.log("waitForTransactionStatusChanges before");

      const statusResult = await waitForTransactionStatusChanges(txId);

      // console.log("waitForTransactionStatusChanges after: ", statusResult);

      return statusResult;
    } catch (err) {
      console.log("[setCarProperty] error", err);
      toast.error(i18n.t(`errors.${err}`), TOAST_CONFIG);
      return err;
    }
  } else {
    return "logout";
  }
}

export async function waitIndidTask(taskId) {
  try {
    console.log("indid init start");
    const clientUser = await Client.init(
      process.env.REACT_APP_RPC_PROVIDER,
      process.env.REACT_APP_INDID_API_KEY,
      { overrideBackendUrl: "https://api.indid.io" },
    );

    const response = await clientUser.waitTask(taskId);
    console.log("[waitIndidTask]", response);

    return response.operationStatus;
  } catch (error) {
    return "FAILED";
  }
}
