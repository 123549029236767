/* Splash Screen page: after every transaction on blockchain, 
this splashscreen let user know if it was successful or not */

//react
import React from "react";

//components
import { StyledButton, Icons, Icon } from "../../index";
import AlfaRomeoLogo from "../../icons/alfaRomeoLogo";

//animations
import { Failure, Loading, Success } from "../../../imports/animations/index";
import Lottie from "react-lottie-player";

const SplashScreen = ({
  type = "success",
  text,
  message,
  buttonText,
  buttonClick,
}) => {
  const isSuccess = type === "success";
  const isFailure = type === "failure";

  return (
    <div className={`absolute top-0 left-0 z-10`}>
      <div
        className={`overflow-hidden flex flex-col justify-center items-center relative text-center text-secondary max-w-600 w-screen h-screen
         ${
           isSuccess
             ? " bg-primaryBackGround"
             : isFailure
             ? "bg-red"
             : "bg-primaryBackground"
         }`}
      >
        {type === "pendingSignup" ? (
          <AlfaRomeoLogo
            width={200}
            height={200}
            className="absolute my-10 top-6"
          />
        ) : (
          <div
            //TODO reason: no sizes available
            className="absolute rounded-full right-1/3"
            style={{
              width: "500px",
              height: "500px",
              top: "-13%",
              background: "rgba(255, 255, 255, 0.2)",
            }}
          ></div>
        )}
        {/* <div
          //TODO reason: no sizes available
          className="absolute rounded-full left-1/4"
          style={{
            width: "580px",
            height: "580px",
            top: "-13%",
            background: "rgba(255, 255, 255, 0.2)",
          }}
        ></div> */}

        <div className={`absolute top-1/4`}>
          {type === "success" ? (
            <div
              //animation-div
              className="w-40 h-40"
            >
              <Lottie loop={false} play animationData={Success} />
            </div>
          ) : type === "failure" ? (
            <div //animation-div
              className="w-40 h-40"
            >
              <Lottie loop={false} play animationData={Failure} />
            </div>
          ) : (
            type !== "pendingSignup" && (
              <div //animation-div
                className="w-40 h-40"
              >
                <Lottie loop={true} play animationData={Loading} />
              </div>
            )
          )}
        </div>
        <div
          //text
          className="absolute text-4xl top-2/4"
          style={{ lineHeight: "60px" }}
        >
          <p style={{ margin: "0" }}>{text}</p>
        </div>
        <div className="absolute text-lg font-normal top-2/3">
          <p>{message}</p>
        </div>
        <div
          className="absolute w-11/12 text-3xl font-normal "
          style={{ bottom: "7%" }}
        >
          {buttonText && buttonClick && (
            <div
              className={
                "font-regular text-3xl w-full flex justify-center items-center"
              }
            >
              <StyledButton
                onClick={buttonClick}
                className={`w-4/5 m-auto h-fit pt-2 pb-2.5 pl-2 pr-2.5 ${
                  isSuccess ? "text-white border-white" : "text-red border-red"
                }`}
                styleType="secondary"
              >
                {buttonText}
              </StyledButton>
            </div>
          )}
        </div>
        <div className="absolute bottom-4">
          <span className="text-text">Powered By </span>
          <span className="font-medium text-text">KNOBS</span>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
