//react
import React from "react";
import { useNavigate } from "react-router-dom";

//components
import { CircleIconButton, Icon, Icons } from "../index";

//style
// import "./topBar.scss";
import { colors } from "../../imports/constants.js";

function TopBar({
  title = "no title",
  goBack = false,
  onClickLeft = null,
  onClickRight = null,
  rightIcon = null,
  logo = false,
  admin = false,
  customBack = false,
}) {
  const navigate = useNavigate();

  const handleClick = e => {
    if (e) {
      goBack ? navigate(-1) : onClickLeft && onClickLeft();
    } else {
      onClickRight && onClickRight();
    }
  };

  return (
    <div className={`w-full fixed top-0 z-10 max-w-600 h-topBarHeight`}>
      <div
        //topBarContainer
        className={`w-full pt-5 pb-5 box-border shadow-none flex justify-between items-center relative h-topBarHeight pl-defaultMargin pr-defaultMargin bg-secondaryBackground`}
      >
        <CircleIconButton
          size="small"
          onClick={() => onClickLeft && handleClick(true)}
          icon={
            <Icon
              name={Icons[goBack || customBack ? "ARROW" : "HAMBURGERMENU"]}
              fill={colors.white}
              style={{
                width: 25,
                height: 20,
              }}
            />
          }
          disabled={!onClickLeft}
          styleType="secondary"
        />
        <span
          //topBarTitle
          className={`text-24 text-center whitespace-no-wrap text-white ${
            !admin && "mx-auto" //needed to center when not cross icon
          }`}
          style={{ wordBreak: "keep-all" }}
        >
          {title}
        </span>

        <Icon
          name={Icons.ALFA_ROMEO_LOGO_BI}
          fill={colors.white}
          secondaryfill={colors["secondary"]}
          style={{ width: 30, height: 30 }}
        />
        {/* <div className="w-7 h-7" /> */}
      </div>
    </div>
  );
}

export default TopBar;
