//react
import React from "react";

//material
import Modal from "@material-ui/core/Modal";

export function CustomModal({
  content = null,
  outsideContent = null,
  opened = false,
  onBackdropClick = null,
  style = {},
  className = "",
}) {
  return (
    <Modal open={opened}>
      <div
        //modalContainer
        className="box-border flex flex-col items-center justify-center w-full h-screen outline-none pt-defaultMargin pb-defaultMargin"
        onClick={onBackdropClick}
      >
        <div
          className={`bg-transparent pt-halfMargin pb-halfMargin pl-defaultMargin pr-defaultMargin w-modal rounded-15 ${className}`}
          onClick={e => e.stopPropagation()}
          style={{
            ...style,
          }}
        >
          {content} {outsideContent}
        </div>
      </div>
    </Modal>
  );
}

//---Documentation---
//open status has to be managed on father component.
//onBackdropClick is triggered when the background is pressed, use it to manage open status.
