import React, { useEffect } from "react";

//components
import QrReader from "react-qr-reader";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { TOAST_CONFIG } from "../../imports/constants";

import { toast } from "react-toastify";
import i18n from "../../imports/i18n";

export default function QrcodeReader({ onScan, onClose }) {
  return (
    <div
      className={`absolute top-0 max-w-600 left-0 w-screen h-full flex items-center justify-around flex-col z-50 bg-black bg-opacity-90`}
    >
      {/* <div className="relative flex flex-col items-center justify-center w-full border rounded-20 border-red"> */}
      {/* <QrReader
        delay={500}
        style={{
          // width: "80vw",
          maxWidth: "560px",
          height: 300,
          // borderRadius: "20px",
          // paddingTop: "20px",
        }}
        onError={e => {
          console.log(e);
          toast.error(i18n.t(e), TOAST_CONFIG);
        }}
        onScan={e => onScan(e)}
        constraints={{ facingMode: "user" }}
      /> */}
      <QrReader
        facingMode="environment"
        delay={500}
        onError={e => {
          console.log(e);
          toast.error(i18n.t(e), TOAST_CONFIG);
        }}
        onScan={e => onScan(e)}
        // chooseDeviceId={()=>selected}
        style={{
          width: "80vw",
          maxWidth: "560px",
          height: 300,
          borderRadius: "20px",
          paddingTop: "20px",
        }}
      />
      {/* </div> */}
      <HighlightOffIcon
        fontSize="large"
        style={{
          fill: "white",
          // marginTop: "70%",
          width: "65px",
          height: "65px",
          zIndex: "11",
        }}
        onClick={onClose}
      />
    </div>
  );
}
