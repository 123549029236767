import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/en.json";
import translationIT from "./locales/it/it.json";
import translationDE from "./locales/de/de.json";
import translationFR from "./locales/fr/fr.json";
import translationES from "./locales/es/es.json";
import translationJA from "./locales/ja/ja.json";

const resources = {
  it: {
    translation: translationIT,
  },
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
  ja: {
    translation: translationJA,
  },
};

i18n.use(initReactI18next).init(
  {
    resources,
    lng: navigator.language.slice(0, 2) || "en",
    fallbackLng: "en",
    //debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  },
  err => {
    if (err) console.error("i18n Error", err);
  },
);

export default i18n;
