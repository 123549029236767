/* IMPORTING PAGES */
import {
  Authentication,
  Home,
  Onboarding,
  CreateNFT,
  Profile,
  Nfts,
  Signup,
  NftDetail,
  SuccessPage,
  ResetPassword,
  EasterEgg,
  Redirects,
  Demo,
  ConfirmEmail,
  MnemonicPage,
  // MyAlfaConnect,
} from "../pages/index";

//formik
import * as Yup from "yup";

export const currentEnv = process.env.REACT_APP_ENV;

export const routes = [
  {
    path: "/",
    component: Home,
    hasTopBar: true,
    topBarIcon: "ADD",
    topBarLabel: "topBar.home",
    topBarFunction: "/createNFT",
    hasDrawer: true,
    inDrawer: true,
    drawerIcon: "NFT",
    drawerLabel: "drawer.home",
    logged: true,
    admin: false,
    props: [],
    redirect: "/auth",
    disabled: false,
  },
  {
    path: "/profile",
    component: Profile,
    hasTopBar: true,
    topBarLabel: "topBar.profile",
    hasDrawer: true,
    inDrawer: true,
    drawerIcon: "PROFILE",
    drawerLabel: "drawer.profile",
    logged: true,
    admin: false,
    props: [],
    redirect: "/auth",
    disabled: false,
  },
  {
    path: "/demo",
    component: Demo,
    hasTopBar: false,
    hasDrawer: true,
    logged: true,
    props: [],
    disabled: false,
  },
  {
    path: "/nft-detail/:id",
    component: NftDetail,
    goBack: true,
    hasTopBar: true,
    topBarLabel: "topBar.nft",
    hasDrawer: false,
    logged: true,
    props: [],
  },
  {
    path: "/test",
    component: EasterEgg,
    hasTopBar: true,
    topBarLabel: "topBar.easter_egg",
    hasDrawer: false,
    goBack: true,
    logged: true,
    props: [],
  },
  {
    path: "/createNFT",
    component: CreateNFT,
    logged: true,
    hasTopBar: false,
    topBarLabel: "topBar.createNFT",
    hasDrawer: false,
    inDrawer: true,
    goBack: true,
    drawerIcon: "NFT",
    drawerLabel: "drawer.createNFT",
    // role: "admin",
    props: [],
    redirect: "/",
  },
  {
    path: "/contracts/:id",
    component: Nfts,
    logged: true,
    props: [],
    redirect: "/auth",
  },
  {
    path: "/mnemonicDisplay",
    component: MnemonicPage,
    logged: true,
    props: [],
    redirect: "/auth",
  },
  {
    path: "/auth",
    component: Authentication,
    logged: false,
    props: [],
    redirect: "/createNFT",
  },
  {
    path: "/signup",
    component: Signup,
    hasTopBar: true,
    logged: false,
    hasDrawer: false,
    hasLogo: true,
    goBack: true,
    topBarLabel: "signup.registration",
    props: [],
  },
  {
    path: "/resetPassword",
    component: ResetPassword,
    logged: false,
    hasDrawer: false,
    hasTopBar: true,
    topBarLabel: "topBar.reset_password",
    topBarFunction: "/auth",
    goBack: false,
    customBack: true,
    props: [],
  },
  {
    path: "/confirmEmail",
    component: ConfirmEmail,
    logged: false,
    hasDrawer: false,
    hasTopBar: false,
    props: [],
  },

  {
    path: "/redirects",
    component: Redirects,
    logged: false,
    hasDrawer: false,
    hasTopBar: false,
    props: [],
    // redirect: "/redirects",
  },
  {
    path: "/onboarding",
    component: Onboarding,
    logged: false,
    onboarding: true,
    props: [],
  },
  {
    path: "/success",
    component: SuccessPage,
    logged: true,
    onboarding: false,
    hasTopBar: false,
    props: [],
  },
];

//yup validations

//auth page

export const loginInitialValues = {
  password: "",
};

export const loginValidation = Yup.object({
  password: Yup.string()
    .min(8, "password_min_char")
    .max(16, "password_max_char")
    .required("insert_password"),
});

export const changePasswordInitialValue = {
  previousPassword: "",
  newPassord: "",
  repeatPassord: "",
};

export const changePasswordValidation = Yup.object({
  previousPassword: Yup.string()
    .min(8, "password_min_char")
    .max(16, "password_max_char")
    .required("insert_password"),
  newPassword: Yup.string()
    .min(8, "password_min_char")
    .max(16, "password_max_char")
    .required("insert_password"),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "password_match")
    .required("insert_password"),
});
