import React, { useState, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import i18n from "../../imports/i18n";

//Components
import { Icon, Icons, SplashScreenStepper } from "../../components";

//Imports
import { usePrevious } from "../../imports/utils";
import { queryCreateNFT } from "./queries";
import { colors, MINT_STEPS } from "../../imports/constants";
import { db } from "../../redux-observables/firebase/firebase";
import { doc } from "firebase/firestore";

import { useFirestoreDocument } from "@react-query-firebase/firestore";

const CAR_DATA = [
  { key: "BRAND", value: "Alfa Romeo" },
  { key: "MODEL", value: "Tonale" },
  { key: "CONFIGURATION", value: "Speciale" },
  { key: "ENGINE", value: "160 Hybrid" },
  // { key: "Date", value: "20/04/2020" },
  { key: "VIN", value: "ZARHA5VP0N3000539" },
  { key: "MILEAGE", value: 45271, unit: "km" },
];

export default function Demo() {
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [step, setStep] = useState(0);
  const [requestId, setRequestId] = useState("0");
  // const minting = true;
  const { isFetching: minting, refetch: createNFT } = useQuery(
    "createNFT",
    async () => {
      setStep(1);
      const { requestId, error } = await queryCreateNFT(setStep);
      setStep(2);
      if (error) {
        setError(error);
      }
      setRequestId(requestId);
      intermediateStep();
    },
    { enabled: false },
  );

  const intermediateStep = () => {
    setTimeout(() => setStep(3), 15000);
  };

  const prevLoading = usePrevious(minting);

  const { data: nft, refetch } = useFirestoreDocument(
    ["nfts", requestId],
    doc(db, "nfts", requestId),
    { subscribe: true },
  );

  useEffect(() => {
    const updateStep = async () => {
      if (error || (requestId && !nft)) {
        refetch();
      }
      if (nft?.data()?.status === "success") {
        setLoader(false);
        setStep(step + 1);
        setTimeout(() => {
          setStep(0);
          navigate("/");
        }, 5000);
      } else if (nft?.data()?.status === "failed") {
        setError(true);
        setLoader(false);
        setStep(step + 1);
        setTimeout(() => {
          setStep(0);
          navigate("/myAlfaConnect");
        }, 5000);
      }
    };
    updateStep();
  }, [requestId, nft]);

  useEffect(() => {
    if (!minting && prevLoading) {
      setLoader(true);
      // setTimeout(() => {
      //   navigate("/login");
      // }, 8000);
    }
  }, [minting, error, prevLoading]);

  return minting ||
    loader ||
    ["success", "failed"].includes(nft?.data()?.status) ? (
    <SplashScreenStepper
      steps={MINT_STEPS}
      currentStep={step}
      requestId={requestId}
      type={minting || loader ? "pending" : !error ? "success" : "failure"}
      message={
        minting || loader
          ? i18n.t("messages.notarization_pending")
          : !error
          ? i18n.t("messages.notarization_success")
          : i18n.t("messages.notarization_failed")
      }
    />
  ) : (
    <div className="w-full h-full bg-primaryBackground pt-topBarHeight">
      <div className="fixed top-0 flex items-center justify-between w-full px-4 py-5 max-w-600 bg-secondaryBackground h-topBarHeight">
        <button onClick={() => navigate("/")}>
          <Icon
            name={Icons.ARROW}
            fill={colors.white}
            style={{
              width: 20,
              height: 20,
            }}
          />
        </button>
        <span className="text-xl text-white">DIGITAL REPORT</span>
        <Icon
          name={Icons.ALFA_ROMEO_LOGO_BI}
          fill={colors.white}
          style={{
            width: 25,
            height: 25,
          }}
        />
      </div>
      <div className="bg-primaryBackground">
        <img
          src="https://cdn.motor1.com/images/mgl/XBogqk/s3/alfa-romeo-tonale-edizione-speciale.jpg"
          alt="Tonale"
        />
        <div className="pb-20 ">
          {CAR_DATA.map(data => (
            <div
              key={data.key}
              className="flex items-center justify-between h-8 p-5 mx-2 my-4 bg-white rounded-md"
            >
              <span className="w-3/4 text-sm font-bold whitespace-normal text-greyDemo">
                {data.key}
              </span>
              <div className="flex items-center justify-end whitespace-nowrap">
                <span className="mr-2 text-16">
                  {data.value.toString().toUpperCase()}
                </span>
                {data?.unit && (
                  <span className="align-text-bottom text-greyDemo text-12">
                    {data.unit}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="fixed flex items-center justify-center w-full max-w-600 bottom-4">
          <button
            className="w-10/12 text-center text-white align-middle border border-white bg-primaryBackground h-button"
            onClick={createNFT}
          >
            CREATE NFT
          </button>
        </div>
      </div>
    </div>
  );
}
