import React from "react";

// i18n
import i18n from "../../imports/i18n";

//Components
import { SplashScreen } from "../../components";

// import "./success.scss";
import { useNavigate } from "react-router-dom";

function SuccessPage(props) {
  const { user, getAllPayments } = props;
  const navigate = useNavigate();

  return (
    <div
      className={`w-full h-screen flex flex-col items-center pt-10 pb-10 box-border`}
    >
      <div
        //animation-div
        className="w-40 h-40"
      >
        <SplashScreen
          type={"success"}
          message={i18n.t("payment_messages.checkout_success", {
            name: user.info.name,
          })}
          buttonText={i18n.t("payment_messages.go_to_home")}
          buttonClick={() => {
            navigate("/");
            getAllPayments();
          }}
        />
      </div>
    </div>
  );
}

export default SuccessPage;
