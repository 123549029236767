// Import the functions you need from the SDKs you need
import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  getDocs,
  getDoc,
  collection,
  where,
  doc,
} from "firebase/firestore";

import { getStorage, ref, getDownloadURL } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const registerWithEmailAndPassword = async (userData, uid) => {
  //TODO: check userData info + password arg (tb hashed?)
  let res = {};
  try {
    res = await axios.post(
      `${process.env.REACT_APP_FIREBASE_ENDPOINT}/createUser`,
      {
        ...userData,
        uid,
      },
    );
  } catch (err) {
    res = err.response.data;
  }
  return res;

  // await setDoc(doc(db, "users", userCredential.user.uid), {
  //   ...userData,
  //   uid: userCredential.user.uid,
  //   email,
  //   propic: DEFAULT_PROPIC,
  //   authProvider: "local",
  //   createdAt: Date.now(),
  // });

  // return createUserWithEmailAndPassword(auth, email, password)
  //   .then(async userCredential => {
  //     await setDoc(doc(db, "users", userCredential.user.uid), {
  //       ...userData,
  //       uid: userCredential.user.uid,
  //       email,
  //       propic: DEFAULT_PROPIC,
  //       authProvider: "local",
  //       createdAt: Date.now(),
  //     });

  //     sendEmailVerification(userCredential.user)
  //       .then(() => console.log("Verification email sent!"))
  //       .catch(err => {
  //         console.error(err);
  //       });

  //     console.log("credential User: ", userCredential);
  //     return {
  //       status: 200,
  //       error: null,
  //       data: {
  //         ...userData,
  //         uid: userCredential.user.uid,
  //         email,
  //         authProvider: "local",
  //         emailVerified: !userCredential.user.emailVerified,
  //       },
  //     };
  //   })
  //   .catch(error => {
  //     console.log(error);
  //     return { status: 400, error };
  //   });
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    const docSnap = await getDoc(doc(db, "users", user.uid));
    const userData = docSnap.data();

    return { ...userData, emailVerified: user.emailVerified };
  } catch (error) {
    console.log(error);
    return { error, status: 400 };
  }
};

const sendPasswordReset = async email => {
  await sendPasswordResetEmail(auth, email);
};

// const setNewPassword = async (newPassword, code) => {
//   await confirmPasswordReset(auth, code, newPassword);
// };
const setNewPassword = async (newPassword, uid) => {
  //TODO: check how to retrieve uid and if it works

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_FIREBASE_ENDPOINT}/changePassword`,
      {
        uid,
        newPassword,
      },
    );
    console.log("response create user: ", data);
    return data;
  } catch (err) {
    console.log(err.response.data);
    return { err };
  }
};

const getDocuments = async (collectionName, query) => {
  const q = query(
    collection(db, collectionName),
    where(query[0], query[1], query[2]),
  );

  const querySnapshot = await getDocs(q);
  return Promise.all(querySnapshot.map(doc => doc.data()));
};

const getAllDocuments = async collectionName => {
  const querySnapshot = await getDocs(collection(db, collectionName));

  return Promise.all(querySnapshot.docs.map(doc => doc.data()));
};

const logout = () => {
  signOut(auth);
};

const getPrivacyPolicy = async () => {
  let url = "";

  try {
    const pathReference = ref(storage, "public/KNOBS_ Privacy Policy.docx.pdf");
    url = await getDownloadURL(pathReference);
  } catch (error) {
    console.log("[getPrivacyPolicy] error", error);
  }

  return url;
};

const getTermsAndConditions = async () => {
  let url = "";
  try {
    const pathReference = ref(
      storage,
      "public/KNOBS_Terms & Conditions.docx.pdf",
    );
    url = await getDownloadURL(pathReference);
  } catch (error) {
    console.log("[getTermsAndConditions] error", error);
  }

  return url;
};

const getCookiePolicy = async () => {
  let url = "";
  try {
    const pathReference = ref(
      storage,
      "public/Cookie Policy of tonale-wallet EN.pdf",
    );
    url = await getDownloadURL(pathReference);
  } catch (error) {
    console.log("[getTermsAndConditions] error", error);
  }

  return url;
};

export {
  auth,
  db,
  storage,
  // signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  setNewPassword,
  getDocuments,
  getAllDocuments,
  logout,
  getPrivacyPolicy,
  getTermsAndConditions,
  getCookiePolicy,
};
