//formik
import * as Yup from "yup";

export const firstStepValidation = Yup.object({
  email: Yup.string().required("Inserire email"),
});

export const secondStepValidation = Yup.object({
  password: Yup.string()
    .min(8, "password_min_char")
    .max(16, "password_max_char")
    .required("insert_password"),
  checkPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "password_match")
    .required("insert_password"),
});
