import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { createContext, useEffect, useState } from "react";
import {
  db,
  storage,
  logInWithEmailAndPassword,
  logout,
  registerWithEmailAndPassword,
  sendPasswordReset,
  setNewPassword,
} from "./firebase";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import i18n from "../../imports/i18n";
import { omit } from "lodash";
import { sdk } from "../../imports/utils";
import { TOAST_CONFIG } from "../../imports/constants";
import axios from "axios";

export const AuthContext = createContext();

const DEFAULT_USER = {
  logged: false,
  needRecover: false,
  exists: null,
  new: false,
  privateKey: null,
  address: null,
  name: null,
  surname: null,
  uid: null,
  sub: null,
  email: null,
};

export const AuthProvider = ({ children }) => {
  const [userAuth, setUserAuth] = useState();
  // const [gigyaToken, setGigyaToken] = useState({
  //   accessToken: null,
  //   expires: null,
  // });

  const [error, setError] = useState(null);
  const [user, setUserData] = useState(
    DEFAULT_USER,
    //JSON.parse(localStorage.getItem("user")) || GIGYA_USER,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState(null);
  const [language, setLanguage] = useState(i18n.language);

  const setUser = data => {
    setUserData({ ...user, ...data });
    localStorage.setItem("user", JSON.stringify(omit(data, ["wallet"])));
  };

  /**
   * Get action from localstorage and set it to state
   */
  useEffect(() => {
    // console.log("authentication use effect");
    setIsLoading(true);

    let action = localStorage.getItem("action");
    action = action === "null" ? null : action;

    const tmpUser = JSON.parse(localStorage.getItem("user")) || DEFAULT_USER;
    if (tmpUser) {
      setUser(tmpUser);

      if (tmpUser.privateKey) {
        sdk.setPrivateKey(tmpUser.privateKey);
      }
    }

    action && setAction(action);

    setIsLoading(false);
  }, []);

  /**
   * Get data from Firebase
   */
  useEffect(() => {
    // console.log("user auth useEffect", window.location.href);
    // const savedData = JSON.parse(localStorage.getItem("user"));

    // if (savedData && !isEqual(savedData, omit(user, ["wallet"]))) {
    // console.log("user before getUserData: ", user);

    if (userAuth?.uid) {
      getUserData(userAuth.uid);
    }

    // const auth = getAuth();
    // onAuthStateChanged(
    //   auth,
    //   data => {
    //     if (!isEqual(prevAuth, data)) {
    //       setUserAuth(data);
    //     }
    //   },
    //   setError,
    // );
  }, [userAuth]);

  useEffect(() => {
    if (error) {
      toast.error(i18n.t(`errors.${error}`), TOAST_CONFIG);
      setError("");
    }
  }, [error]);

  useEffect(() => {
    const effect = async () => {
      // console.log("gigya and user useEffect");
      if (!user?.logged) {
        // if user is not logged in, but gigya token exists, it means he is trying to access the webapp
        // if (gigyaToken.accessToken) {
        //   // reset accessToken in order to delete cached data
        //   setGigyaToken({
        //     accessToken: null,
        //     refreshToken: null,
        //   });

        //otherwise check if the email is setted
        //if so it means he already completed the login on gigya

        if (user?.email && user.exists === null) {
          //check if it is the first time he access the webapp and save the info
          setUserData({ ...user, exists: await queryExistance() });
        }

        // }
      }
    };
    effect();
  }, [user]);

  const getUserData = async uid => {
    setIsLoading(true);

    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);

    const privateKey = localStorage.getItem("privateKey");
    if (privateKey) sdk.setPrivateKey(privateKey);
    localStorage.removeItem("privateKey");
    // console.log("user in get user data: ", user);
    setUser({
      ...user,
      ...omit(docSnap.data(), ["wallet"]),
      emailVerified: !!userAuth?.emailVerified,
      wallet: privateKey ? new ethers.Wallet(privateKey) : user?.wallet,
      //if I can import pk, the user exists already, RIGHT?
      exists: true,
    });
    setIsLoading(false);
  };

  /**
   * Function called on login redirect to obtain user data and gigya access
   * @param {*} code
   */
  const getGigyaToken = async code => {
    try {
      const region = localStorage.getItem("region");

      const { data } = await axios.get(
        `${process.env.REACT_APP_FIREBASE_ENDPOINT}/getGigyaToken?code=${code}&region=${region}`,
      );

      // setGigyaToken({
      //   accessToken: data.access_token,
      //   refreshToken: data.refresh_token,
      //   tokenId: data.id_token,
      // });

      const { data: userData } = await axios.get(
        `${process.env.REACT_APP_FIREBASE_ENDPOINT}/userData?token=${data.access_token}&region=${region}`,
      );

      // console.log("[getGigyaToken] response", JSON.stringify(userData));

      setUser(userData);
    } catch (error) {
      console.log(error);
    }
  };

  const updateUser = async data => {
    try {
      await updateDoc(doc(db, "users", user.sub || user.uid), data);
      setUser({ ...user, ...data });
    } catch (e) {
      // toast.error(i18n.t("errors"))
    }
  };

  const registerUser = async userData => {
    setIsLoading(true);
    const wallet = ethers.Wallet.createRandom();
    localStorage.setItem("privateKey", wallet.privateKey);

    userData.wallet = await wallet.encrypt(userData.password);
    userData.address = wallet.address;
    userData.new = true;

    // console.log("Registering user...");

    const { status, data, error } = await registerWithEmailAndPassword(
      userData,
      user.uid,
    );

    if (status === 200) {
      setUserAuth(data);
      setUser({
        ...omit(userData, [
          "password",
          "repeatPassword",
          // "mnemonic",
        ]),
        uid: data.uid,
        wallet,
        logged: false,
        emailVerified: data.emailVerified,
        exists: true,
      });
      toast.success(i18n.t("messages.succesfully_registered"), TOAST_CONFIG);
    } else if (error) {
      console.log(error);
      toast.error(i18n.t(`errors.${error}`), TOAST_CONFIG);
    }

    setIsLoading(false);
  };

  const loginUser = async (email, password) => {
    let needRecover = false;
    const doc = await logInWithEmailAndPassword(email, password);

    setIsLoading(true);

    if (!doc.emailVerified && !doc.error) {
      setIsLoading(false);
      // toast.error(i18n.t(`errors.need_to_verify_email`), TOAST_CONFIG);
      setError("need_to_verify_email");

      return;
    }

    if (!doc.error) {
      let wallet = null;
      try {
        wallet = await ethers.Wallet.fromEncryptedJson(doc.wallet, password);

        sdk.setPrivateKey(wallet.privateKey);
        localStorage.setItem("privateKey", wallet.privateKey);
      } catch (err) {
        /**
         * If the user can't recover the wallet due to password change we need to recoer it
         */
        wallet = doc.wallet;
        needRecover = true;
      }

      setUser({
        ...user,
        ...doc,
        logged: true,
        wallet,
        needRecover,
        mnemonic: wallet?.mnemonic?.phrase,
        privateKey: wallet?.privateKey,
      });
    } else if (doc.error) {
      setIsLoading(false);
      setError(doc.error);
      setUser(user);
    }
    setIsLoading(false);
    return;
  };

  const queryExistance = async () => {
    setIsLoading(true);
    const { data } = await axios.post(
      `${process.env.REACT_APP_FIREBASE_ENDPOINT}/checkUserExistance`,
      { uid: user.uid },
    );

    setIsLoading(false);
    return data;
  };

  const resetPassword = async email => {
    try {
      await sendPasswordReset(email);
      toast.success(i18n.t("reset_password.request_sent"), TOAST_CONFIG);
    } catch (err) {
      toast.error(i18n.t("reset_password.sending_request_error"), TOAST_CONFIG);
    }
    // setUser({ ...user, needrecover: true });
  };

  // const sendNewPassword = async (newPassword, code) => {
  //   try {
  //     await setNewPassword(newPassword, code);

  //     setUser({ ...user, needRecover: true });
  //     toast.success(i18n.t("reset_password.request_sent"), TOAST_CONFIG);
  //     return true;
  //   } catch (err) {
  //     toast.error(i18n.t("reset_password.sending_request_error"), TOAST_CONFIG);
  //     return false;
  //   }
  // };

  const sendNewPassword = async (newPassword, uid) => {
    try {
      await setNewPassword(newPassword, uid);
      setUser({ ...user, needRecover: true });
      toast.success(i18n.t("reset_password.request_sent"), TOAST_CONFIG);
      return true;
    } catch (err) {
      toast.error(i18n.t("reset_password.sending_request_error"), TOAST_CONFIG);
      return false;
    }
  };

  const recoverWallet = async ({ newPassword, seed }) => {
    setIsLoading(true);
    // console.log("Recover wallet procedure...");

    const wallet = ethers.Wallet.fromMnemonic(seed);

    const walletEnc = await wallet.encrypt(newPassword);

    await updateDoc(doc(db, "users", user.sub || user.uid), {
      wallet: walletEnc,
    });

    setUser({
      ...user,
      wallet: wallet,
      needRecover: false,
      privateKey: wallet.privateKey,
    });

    setIsLoading(false);
  };

  const saveAction = mode => {
    localStorage.setItem("action", mode);
    setAction(mode);
  };

  const saveRegion = (region, forceUpdate) => {
    // console.log("saving new region -> ", region);
    localStorage.setItem("region", region);
    /* const savedRegion = localStorage.getItem("region");
    if ((region && !savedRegion) || forceUpdate) {
      console.log("saving new region -> ", region);
      localStorage.setItem("region", region);
    } else {
      console.log("region already set -> ", savedRegion);
    } */
  };

  const getRegion = () => localStorage.getItem("region");

  const signOut = async () => {
    setUserAuth(null);
    setUser(DEFAULT_USER);

    // Delete localStorage private key reference
    localStorage.removeItem("privateKey");

    logout();
  };

  const uploadProPic = async file => {
    const imagesRef = ref(storage, `/images/${userAuth.uid}`);

    uploadBytes(imagesRef, file)
      .then(snapshot => {
        getDownloadURL(imagesRef).then(url => {
          uploadUserData({ propic: url });
          setUser({
            ...user,
            propic: url,
          });
          toast.success(i18n.t("messages.done"), TOAST_CONFIG);
        });
      })
      .catch(error => console.log("error ", error));
  };

  const uploadUserData = async data => {
    try {
      await updateDoc(doc(db, "users", userAuth.uid), data);
    } catch (error) {
      console.log(error);
      toast.error(i18n.t("errors.unable_to_update"), TOAST_CONFIG);
    }
  };

  const changeLanguage = lang => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  const getRedirectUrl = () => {
    const region = localStorage.getItem("region");
    switch (region) {
      case "na":
        return `${process.env.REACT_APP_GIGYA_ENDPOINT_NA}/${process.env.REACT_APP_GIGYA_API_KEY_NA}/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID_NA}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;

      case "iap":
        return `${process.env.REACT_APP_GIGYA_ENDPOINT_IAP}/${process.env.REACT_APP_GIGYA_API_KEY_IAP}/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID_IAP}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;

      default:
        return `${process.env.REACT_APP_GIGYA_ENDPOINT}/${process.env.REACT_APP_GIGYA_API_KEY}/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        userAuth,
        user,
        isLoading,
        error,
        action,
        language,
        updateUser,
        setUser,
        registerUser,
        loginUser,
        resetPassword,
        sendNewPassword,
        recoverWallet,
        signOut,
        uploadProPic,
        uploadUserData,
        saveAction,
        saveRegion,
        getRegion,
        getGigyaToken,
        getRedirectUrl,
        changeLanguage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
