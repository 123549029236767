export const theme = {
  primary: {
    base: `relative w-full h-12 bg-primary bg-radial-gradient border-0 flex justify-between items-center px-4`,
    variants: {
      default: "",
      disabled: "shadow-none border-0 opacity-50",
    },
  },
  secondary: {
    base: `relative w-full h-12 bg-primaryBackground border border-solid border-text flex justify-between items-center px-4`,
    variants: {
      default: "",
      disabled: "shadow-none text-lightGrey border-0 bg-disabledGrey",
    },
  },
  tertiary: {
    base: `relative w-full h-12 bg-primaryBackground flex justify-between items-center px-4`,
    variants: {
      default: "",
      disabled: "shadow-none text-lightGrey border-0 bg-disabledGrey",
    },
  },
  avatarUpload: {
    base: `w-24 h-24 rounded-20 border border-black`,
    variants: {
      default: "",
      disabled: "",
    },
  },
  NFTUpload: {
    base: `w-full h-40 rounded-20 border-2 border-dashed border-primary`,
    variants: {
      default: "",
      disabled: "",
    },
  },
};

export const text = {
  primary: {
    base: `text-11 normal-case text-text whitespace-nowrap `,
    variants: {
      default: "",
      disabled: "text-lightGrey",
    },
  },
  secondary: {
    base: `text-11 normal-case text-text whitespace-nowrap`,
    variants: {
      default: "",
      disabled: "text-lightGrey",
    },
  },
  tertiary: {
    base: `text-11 normal-case text-text whitespace-nowrap hover:underline`,
    variants: {
      default: "",
      disabled: "text-lightGrey",
    },
  },
  avatarUpload: {
    base: ``,
    variants: {
      default: "",
      disabled: "text-lightGrey",
    },
  },
  NFTUpload: {
    base: `opacity-50`,
    variants: {
      default: "",
      disabled: "",
    },
  },
};
