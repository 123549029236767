//react
import React from "react";

//style
// import "./stepper.scss";

export default function Stepper({ step = 1 }) {
  return (
    <div id="stepper" className="flex justify-center w-full">
      <>
        <div
          //className={`single-step${step === 1 ? "-selected" : ""}`}
          className={`transition delay-250 ease-in-out h-2 mr-1 ml-1 bg-primaryBackground ${
            step === 1 ? "w-5" : "w-2 "
          }`}
          style={{ borderRadius: step === 1 ? "20px" : "50%" }}
        ></div>
        <div
          //className={`single-step${step === 1 ? "-selected" : ""}`}
          className={`transition delay-250 ease-in-out h-2 mr-1 ml-1 bg-primaryBackground ${
            step === 1 ? "w-5" : "w-2 "
          }`}
          style={{ borderRadius: step === 1 ? "20px" : "50%" }}
        ></div>
        <div
          //className={`single-step${step === 1 ? "-selected" : ""}`}
          className={`transition delay-250 ease-in-out h-2 mr-1 ml-1 bg-primaryBackground ${
            step === 1 ? "w-5" : "w-2 "
          }`}
          style={{ borderRadius: step === 1 ? "20px" : "50%" }}
        ></div>
      </>
    </div>
  );
}
